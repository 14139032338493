/*

*/
html {
    background-color: #f0f3f4;
}

body {
    font-family: "Helvetica Neue", "Source Sans Pro", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #868689;
    background-color: transparent;
    background-image: none !important;
    -webkit-font-smoothing: antialiased;
    line-height: 1.42857143;
}

*:focus {
    outline: 0 !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}

a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}

label {
    font-weight: normal;
}

small,
.small {
    font-size: 13px;
}

.badge,
.label {
    font-weight: bold;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}

.badge.bg-light,
.label.bg-light {
    text-shadow: none;
}

.badge {
    background-color: #cfdadd;
}

.badge.up {
    position: relative;
    top: -10px;
    padding: 3px 6px;
    margin-left: -10px;
}

.badge-sm {
    font-size: 85%;
    padding: 2px 5px !important;
}

.label-sm {
    padding-top: 0;
    padding-bottom: 1px;
}

.badge-white {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.35);
    padding: 2px 6px;
}

.badge-empty {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: inherit;
}

blockquote {
    border-color: #dee5e7;
}

.caret-white {
    border-top-color: #fff;
    border-top-color: rgba(255, 255, 255, 0.65);
}

a:hover .caret-white {
    border-top-color: #fff;
}

.thumbnail {
    border-color: #dee5e7;
}

.progress {
    background-color: #edf1f2;
}

.progress-xxs {
    height: 2px;
}

.progress-xs {
    height: 6px;
}

.progress-sm {
    height: 12px;
}

.progress-sm .progress-bar {
    font-size: 10px;
    line-height: 1em;
}

.progress,
.progress-bar {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.progress-bar-primary {
    background-color: #333787;
}

.progress-bar-info {
    background-color: #23b7e5;
}

.progress-bar-success {
    background-color: #51c4cf;
}

.progress-bar-warning {
    background-color: #868689;
}

.progress-bar-danger {
    background-color: #ff676d;
}

.progress-bar-black {
    background-color: #51c4cf;
}

.progress-bar-white {
    background-color: #fff;
}

.accordion-group,
.accordion-inner {
    border-color: #dee5e7;
    border-radius: 5px;
}

.alert {
    font-size: 13px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
}

.alert .close i {
    font-size: 12px;
    font-weight: normal;
    display: block;
}

.form-control {
    border-color: #cfdadd;
    border-radius: 5px;
}

.form-control,
.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control:focus {
    border-color: #23b7e5;
}

.form-horizontal .control-label.text-left {
    text-align: left;
}

.form-control-spin {
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 50%;
    margin-top: -7px;
}

.input-group-addon {
    border-color: #cfdadd;
    background-color: #edf1f2;
}

.list-group {
    border-radius: 5px;
}

.list-group.no-radius .list-group-item {
    border-radius: 0 !important;
}

.list-group.no-borders .list-group-item {
    border: none;
}

.list-group.no-border .list-group-item {
    border-width: 1px 0;
}

.list-group.no-bg .list-group-item {
    background-color: transparent;
}

.list-group-item {
    border-color: #e7ecee;
    padding-right: 15px;
}

a.list-group-item:hover,
a.list-group-item:focus,
a.list-group-item.hover {
    background-color: #f6f8f8;
}

.list-group-item.media {
    margin-top: 0;
}

.list-group-item.active {
    color: #fff;
    border-color: #23b7e5 !important;
    background-color: #23b7e5 !important;
}

.list-group-item.active .text-muted {
    color: #ace4f5 !important;
}

.list-group-item.active a {
    color: #fff;
}

.list-group-item.focus {
    background-color: #e4eaec !important;
}

.list-group-item.select {
    position: relative;
    z-index: 1;
    background-color: #dbeef9 !important;
    border-color: #c5e4f5;
}

.list-group-alt .list-group-item:nth-child(2n+2) {
    background-color: rgba(0, 0, 0, 0.02) !important;
}

.list-group-lg .list-group-item {
    padding-top: 15px;
    padding-bottom: 15px;
}

.list-group-sm .list-group-item {
    padding: 6px 10px;
}

.list-group-sp .list-group-item {
    margin-bottom: 5px;
    border-radius: 3px;
}

.list-group-item > .badge {
    margin-right: 0;
}

.list-group-item > .fa-chevron-right {
    float: right;
    margin-top: 4px;
    margin-right: -5px;
}

.list-group-item > .fa-chevron-right + .badge {
    margin-right: 5px;
}

.nav-pills.no-radius > li > a {
    border-radius: 0;
}

.nav-pills > li.active > a {
    color: #fff !important;
    background-color: #23b7e5;
}

.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:active {
    background-color: #19a9d5;
}

.nav > li > a:hover,
.nav > li > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.nav.nav-lg > li > a {
    padding: 20px 20px;
}

.nav.nav-md > li > a {
    padding: 15px 15px;
}

.nav.nav-sm > li > a {
    padding: 6px 12px;
}

.nav.nav-xs > li > a {
    padding: 4px 10px;
}

.nav.nav-xxs > li > a {
    padding: 1px 10px;
}

.nav.nav-rounded > li > a {
    border-radius: 20px;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0.05);
}

.nav-tabs {
    border-color: #dee5e7;
}

.nav-tabs > li > a {
    border-radius: 5px 5px 0 0;
    border-bottom-color: #dee5e7;
}

.nav-tabs > li:hover > a,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover {
    border-color: #dee5e7;
}

.nav-tabs > li.active > a {
    border-bottom-color: #fff !important;
}

.nav-tabs-alt .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
}

.nav-tabs-alt .nav-tabs > li > a {
    border-radius: 0;
    border-color: transparent !important;
    background: transparent !important;
    border-bottom-color: #dee5e7 !important;
}

.nav-tabs-alt .nav-tabs > li.active > a {
    border-bottom-color: #23b7e5 !important;
}

.tab-container {
    margin-bottom: 15px;
}

.tab-container .tab-content {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #dee5e7;
    border-top-width: 0;
    border-radius: 0 0 5px 5px;
}

.pagination > li > a {
    border-color: #dee5e7;
}

.pagination > li > a:hover,
.pagination > li > a:focus {
    border-color: #dee5e7;
    background-color: #edf1f2;
}

.panel {
    border-radius: 5px;
}

.panel .accordion-toggle {
    font-size: 14px;
    display: block;
    cursor: pointer;
}

.panel .list-group-item {
    border-color: #edf1f2;
}

.panel.no-borders {
    border-width: 0;
}

.panel.no-borders .panel-heading,
.panel.no-borders .panel-footer {
    border-width: 0;
}

.panel-heading {
    border-radius: 5px 5px 0 0;
}

.panel-default .panel-heading {
    background-color: #f6f8f8;
}

.panel-heading.no-border {
    margin: -1px -1px 0 -1px;
    border: none;
}

.panel-heading .nav {
    margin: -10px -15px;
}

.panel-heading .list-group {
    background: transparent;
}

.panel-footer {
    border-color: #edf1f2;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
}

.panel-default {
    border-color: #dee5e7;
}

.panel-default > .panel-heading,
.panel-default > .panel-footer {
    border-color: #edf1f2;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
    border-top: 1px solid #eaedef;
}

.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 8px 15px;
    border-top: 1px solid #eaeff0;
}

.table > thead > tr > th {
    padding: 8px 15px;
    border-bottom: 1px solid #eaeff0;
}

.table-bordered {
    border-color: #eaeff0;
}

.table-bordered > tbody > tr > td {
    border-color: #eaeff0;
}

.table-bordered > thead > tr > th {
    border-color: #eaeff0;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
    background-color: #fafbfc;
}

.table-striped > thead > th {
    background-color: #fafbfc;
    border-right: 1px solid #eaeff0;
}

.table-striped > thead > th:last-child {
    border-right: none;
}

.well,
pre {
    background-color: #edf1f2;
    border-color: #dee5e7;
}

.dropdown-menu {
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #dee5e7;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-menu.pull-left {
    left: 100%;
}

.dropdown-menu > .panel {
    border: none;
    margin: -5px 0;
}

.dropdown-menu > li > a {
    padding: 5px 15px;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background-image: none;
    filter: none;
    background-color: #edf1f2 !important;
    color: #868689;
}

.dropdown-header {
    padding: 5px 15px;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
    background-color: #edf1f2 !important;
    color: #868689;
}

.dropdown-submenu:hover > .dropdown-menu,
.dropdown-submenu:focus > .dropdown-menu {
    display: block;
}

.dropdown-submenu.pull-left {
    float: none !important;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
}

.dropdown-submenu .dropdown-menu {
    left: 100%;
    top: 0;
    margin-top: -6px;
    margin-left: -1px;
}

.dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
}

.btn-group > .btn {
    margin-left: -1px;
}

/*cols*/
.col-lg-2-4 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.col-0 {
    clear: left;
}

.row.no-gutter {
    margin-left: 0;
    margin-right: 0;
}

.no-gutter [class*="col"] {
    padding: 0;
}

.row-sm {
    margin-left: -10px;
    margin-right: -10px;
}

.row-sm > div {
    padding-left: 10px;
    padding-right: 10px;
}

.modal-backdrop {
    background-color: #000000;
}

.modal-backdrop.in {
    opacity: 0.48;
    filter: alpha(opacity=48);
}

.modal-over {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
}

.modal-center {
    position: absolute;
    left: 50%;
    top: 50%;
}

/*layout*/
html,
body {
    width: 100%;
    height: 100%;
}

body {
    overflow-x: hidden;
}

.app {
    height: auto;
    min-height: 100vh;
    width: 100%;
    position: relative;
}

.app:before {
    content: "";
    position: absolute;
    width: inherit;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-color: #f0f3f4;
    border: inherit;
    display: block;
}

.app-header-fixed {
    padding-top: 50px;
}

.app-header-fixed .app-header {
    position: fixed;
    top: 0;
    width: 100%;
}

.app-header {
    z-index: 1025;
    border-radius: 0;
}

.app-aside {
    float: left;
}

.app-aside:before {
    content: "";
    position: absolute;
    width: inherit;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-color: inherit;
    border: inherit;
}

.app-aside-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    max-width: 200px;
}

.app-aside-folded .app-aside-footer {
    max-width: 60px;
}

.app-aside-footer ~ div {
    padding-bottom: 50px;
}

.app-aside-right {
    padding-bottom: 50px;
}

.app-content {
    min-height: 100vh;
    height: 100%;
}

.app-content:before,
.app-content:after {
    content: " ";
    display: table;
}

.app-content:after {
    clear: both;
}

.app-content-full {
    position: absolute;
    top: 50px;
    bottom: 50px;
    height: auto;
    width: auto !important;
    padding: 0 !important;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.app-content-full.h-full {
    bottom: 0;
    height: auto;
}

.app-content-body {
    padding-bottom: 50px;
    float: left;
    width: 100%;
}

.app-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1005;
}

.app-footer.app-footer-fixed {
    position: fixed;
}

.hbox {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    height: 100%;
}

.hbox .col {
    display: table-cell;
    vertical-align: top;
    height: 100%;
    float: none;
}

.v-middle {
    vertical-align: middle !important;
}

.v-top {
    vertical-align: top !important;
}

.v-bottom {
    vertical-align: bottom !important;
}

.vbox {
    display: table;
    border-spacing: 0;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 240px;
}

.vbox .row-row {
    display: table-row;
    height: 100%;
}

.vbox .row-row .cell {
    position: relative;
    height: 100%;
    width: 100%;
}

.ie .vbox .row-row .cell {
    display: table-cell;
    overflow: auto;
}

.ie .vbox .row-row .cell .cell-inner {
    overflow: visible !important;
}

.vbox .row-row .cell .cell-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
}

.navbar {
    border-width: 0;
    border-radius: 0;
    margin: 0;
}

.navbar .navbar-form-sm {
    margin-top: 10px;
    margin-bottom: 10px;
}

.navbar-md {
    min-height: 60px;
}

.navbar-md .navbar-btn {
    margin-top: 13px;
}

.navbar-md .navbar-form {
    margin-top: 15px;
}

.navbar-md .navbar-nav > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
}

.navbar-md .navbar-brand {
    line-height: 60px;
}

.navbar-header > button {
    text-decoration: none;
    line-height: 30px;
    font-size: 16px;
    padding: 10px 17px;
    border: none;
    background-color: transparent;
}

.navbar-brand {
    float: none !important;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    height: auto;
    line-height: 50px;
    display: inline-block;
    padding: 0 20px;
}

.navbar-brand:hover {
    text-decoration: none;
}

.navbar-brand img {
    max-height: 20px;
    margin-top: -4px;
    vertical-align: middle;
    display: inline;
}

@media (min-width: 768px) {
    .app-aside,
    .navbar-header {
        width: 200px;
    }

    .navbar-collapse,
    .app-content,
    .app-footer {
        margin-left: 200px;
    }

    .app-aside-right {
        position: absolute;
        top: 50px;
        bottom: 0;
        right: 0;
        z-index: 1000;
    }

    .app-aside-right.pos-fix {
        z-index: 1010;
    }

    .visible-folded {
        display: none;
    }

    .app-aside-folded .hidden-folded {
        display: none !important;
    }

    .app-aside-folded .visible-folded {
        display: inherit;
    }

    .app-aside-folded .text-center-folded {
        text-align: center;
    }

    .app-aside-folded .pull-none-folded {
        float: none !important;
    }

    .app-aside-folded .w-auto-folded {
        width: auto;
    }

    .app-aside-folded .app-aside,
    .app-aside-folded .navbar-header {
        width: 60px;
    }

    .app-aside-folded .navbar-collapse,
    .app-aside-folded .app-content,
    .app-aside-folded .app-footer {
        margin-left: 60px;
    }

    .app-aside-folded .app-header .navbar-brand {
        display: block;
        padding: 0;
    }

    .app-aside-fixed .app-aside:before {
        position: fixed;
    }

    .app-aside-fixed .app-header .navbar-header {
        position: fixed;
    }

    .app-aside-fixed .aside-wrap {
        position: fixed;
        overflow: hidden;
        top: 50px;
        bottom: 0;
        left: 0;
        width: 199px;
        z-index: 1000;
    }

    .app-aside-fixed .aside-wrap .navi-wrap {
        width: 217px;
        position: relative;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .app-aside-fixed .aside-wrap .navi-wrap::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .app-aside-fixed .aside-wrap .navi-wrap::-webkit-scrollbar:vertical {
        width: 17px;
    }

    .app-aside-fixed .aside-wrap .navi-wrap > * {
        width: 200px;
    }

    .smart .app-aside-fixed .aside-wrap .navi-wrap {
        width: 200px;
    }

    .app-aside-fixed.app-aside-folded .app-aside {
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 1010;
    }

    .app-aside-fixed.app-aside-folded .aside-wrap {
        width: 59px;
    }

    .app-aside-fixed.app-aside-folded .aside-wrap .navi-wrap {
        width: 77px;
    }

    .app-aside-fixed.app-aside-folded .aside-wrap .navi-wrap > * {
        width: 60px;
    }

    .smart .app-aside-fixed.app-aside-folded .aside-wrap .navi-wrap {
        width: 60px;
    }

    .bg-auto:before {
        content: "";
        position: absolute;
        width: inherit;
        top: 0;
        bottom: 0;
        z-index: -1;
        background-color: inherit;
        border: inherit;
    }

    .bg-auto.b-l:before {
        margin-left: -1px;
    }

    .bg-auto.b-r:before {
        margin-right: -1px;
    }

    .col.show {
        display: table-cell !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hbox-auto-sm {
        display: block;
    }

    .hbox-auto-sm > .col {
        width: auto;
        height: auto;
        display: block;
    }

    .hbox-auto-sm > .col.show {
        display: block !important;
    }

    .hbox-auto-sm .vbox {
        height: auto;
    }

    .hbox-auto-sm .cell-inner {
        position: static !important;
    }
}

@media (max-width: 767px) {
    body {
        height: auto;
        min-height: 100%;
    }

    .navbar-fixed-bottom {
        position: fixed;
    }

    .app-aside {
        float: none;
    }

    .app-content-full {
        position: relative;
        top: 0;
        width: 100% !important;
    }

    .hbox-auto-xs {
        display: block;
    }

    .hbox-auto-xs > .col {
        width: auto;
        height: auto;
        display: block;
    }

    .hbox-auto-xs .vbox {
        height: auto;
    }

    .hbox-auto-xs .cell-inner {
        position: static !important;
    }

    .navbar-nav {
        margin-top: 0;
        margin-bottom: 0;
    }

    .navbar-nav > li > a {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
    }

    .navbar-nav > li > a .up {
        top: 0;
    }

    .navbar-nav > li > a .avatar {
        width: 30px;
        margin-top: -5px;
    }

    .navbar-nav .open .dropdown-menu {
        background-color: #fff;
    }

    .navbar-form {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1);
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .navbar-form .form-group {
        margin-bottom: 0;
    }
}

html.bg {
    background: url('../img/bg.jpg');
    background-attachment: fixed;
    background-size: cover;
}

.app.container {
    padding-left: 0;
    padding-right: 0;
}

@media (min-width: 768px) {
    .app.container {
        width: 750px;
        -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    }

    .app.container .app-aside {
        overflow-x: hidden;
    }

    .app.container.app-aside-folded .app-aside {
        overflow-x: visible;
    }

    .app.container.app-aside-fixed .aside-wrap {
        left: inherit;
    }

    .app.container.app-aside-fixed.app-aside-folded .app-aside > ul.nav {
        position: absolute;
    }

    .app.container .app-header,
    .app.container .app-aside {
        max-width: 750px;
    }

    .app.container .app-footer-fixed {
        left: auto;
        right: auto;
        width: 100%;
        max-width: 550px;
    }

    .app.container.app-aside-folded .app-footer-fixed {
        max-width: 690px;
    }

    .app.container.app-aside-dock .app-footer-fixed {
        max-width: 750px;
    }
}

@media (min-width: 992px) {
    .app.container {
        width: 970px;
    }

    .app.container .app-header,
    .app.container .app-aside {
        max-width: 970px;
    }

    .app.container .app-footer-fixed {
        max-width: 770px;
    }

    .app.container.app-aside-folded .app-footer-fixed {
        max-width: 910px;
    }

    .app.container.app-aside-dock .app-footer-fixed {
        max-width: 970px;
    }
}

@media (min-width: 1200px) {
    .app.container {
        width: 1170px;
    }

    .app.container .app-header,
    .app.container .app-aside {
        max-width: 1170px;
    }

    .app.container .app-footer-fixed {
        max-width: 970px;
    }

    .app.container.app-aside-folded .app-footer-fixed {
        max-width: 1110px;
    }

    .app.container.app-aside-dock .app-footer-fixed {
        max-width: 1170px;
    }
}

.nav-sub {
    opacity: 0;
    height: 0;
    overflow: hidden;
    margin-left: -20px;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
}

.active > .nav-sub,
.app-aside-folded li:hover > .nav-sub,
.app-aside-folded li:focus > .nav-sub,
.app-aside-folded li:active > .nav-sub {
    opacity: 1;
    margin-left: 0;
    height: auto !important;
    overflow: auto;
}

.nav-sub-header {
    display: none !important;
}

.nav-sub-header a {
    padding: 15px 20px;
}

.navi ul.nav li {
    display: block;
    position: relative;
}

.navi ul.nav li li a {
    padding-left: 55px;
}

.navi ul.nav li li ul {
    display: none;
}

.navi ul.nav li li.active > ul {
    display: block;
}

.navi ul.nav li a {
    font-weight: normal;
    text-transform: none;
    display: block;
    padding: 10px 20px;
    position: relative;
    -webkit-transition: background-color 0.2s ease-in-out 0s;
    transition: background-color 0.2s ease-in-out 0s;
}

.navi ul.nav li a .badge,
.navi ul.nav li a .label {
    font-size: 11px;
    padding: 2px 5px;
    margin-top: 2px;
}

.navi ul.nav li a > i {
    margin: -10px -10px;
    line-height: 40px;
    width: 40px;
    float: left;
    margin-right: 5px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.navi ul.nav li a > i:before {
    position: relative;
    z-index: 2;
}

@media (min-width: 768px) {
    .app-aside-folded .nav-sub-header {
        display: block !important;
    }

    .app-aside-folded .nav-sub-header a {
        padding: 15px 20px !important;
    }

    .app-aside-folded .navi > ul > li > a {
        position: relative;
        padding: 0;
        text-align: center;
        height: 50px;
        border: none;
    }

    .app-aside-folded .navi > ul > li > a span {
        display: none;
    }

    .app-aside-folded .navi > ul > li > a span.pull-right {
        display: none !important;
    }

    .app-aside-folded .navi > ul > li > a i {
        width: auto;
        float: none !important;
        display: block;
        font-size: 16px;
        margin: 0;
        line-height: 50px;
        border: none !important;
    }

    .app-aside-folded .navi > ul > li > a i b {
        left: 0 !important;
    }

    .app-aside-folded .navi > ul > li > a .badge,
    .app-aside-folded .navi > ul > li > a .label {
        position: absolute;
        right: 12px;
        top: 8px;
        z-index: 3;
    }

    .app-aside-folded .navi > ul > li > ul {
        height: 0 !important;
        position: absolute;
        left: 100%;
        top: 0 !important;
        z-index: 1050;
        width: 200px;
        -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }

    .app-aside-folded .navi li li a {
        padding-left: 20px !important;
    }

    .app-aside-folded.app-aside-fixed .app-aside > ul.nav {
        z-index: 1010;
        opacity: 1;
        height: auto;
        overflow: visible;
        overflow-y: auto;
        display: block;
        width: 260px;
        left: 80px;
        position: fixed;
        -webkit-overflow-scrolling: touch;
    }

    .app-aside-folded.app-aside-fixed .app-aside > ul.nav:before {
        content: "";
        width: 60px;
        height: 50px;
        position: absolute;
        left: -60px;
        top: 0;
    }

    .app-aside-folded.app-aside-fixed .app-aside > ul.nav a {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

@media (max-width: 767px) {
    html,
    body {
        overflow-x: hidden !important;
    }

    .app {
        overflow-x: hidden;
    }

    .app-content {
        -webkit-transition: -webkit-transform 0.2s ease;
        -moz-transition: -moz-transform 0.2s ease;
        -o-transition: -o-transform 0.2s ease;
        transition: transform 0.2s ease;
    }

    .off-screen {
        position: fixed;
        top: 50px;
        bottom: 0;
        width: 75%;
        display: block !important;
        visibility: visible;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 1010;
    }

    .off-screen + * {
        background-color: #f0f3f4;
        -webkit-transition: -webkit-transform 0.2s ease;
        -moz-transition: -moz-transform 0.2s ease;
        -o-transition: -o-transform 0.2s ease;
        transition: transform 0.2s ease;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translate3d(75%, 0px, 0px);
        transform: translate3d(75%, 0px, 0px);
        overflow: hidden;
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1015;
        padding-top: 50px;
    }

    .off-screen + * .off-screen-toggle {
        display: block !important;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1020;
    }

    .off-screen.pull-right {
        right: 0;
    }

    .off-screen.pull-right + * {
        -webkit-transform: translate3d(-75%, 0px, 0px);
        transform: translate3d(-75%, 0px, 0px);
    }
}

@media (min-width: 992px) {
    .app-aside-dock .app-content,
    .app-aside-dock .app-footer {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .app-aside-dock .app-aside-footer ~ div {
        padding-bottom: 0;
    }

    .app-aside-dock.app-aside-fixed.app-header-fixed {
        padding-top: 115px;
    }

    .app-aside-dock.app-aside-fixed .app-aside {
        position: fixed;
        top: 50px;
        width: 100%;
        z-index: 1000;
    }

    .app-aside-dock .app-aside,
    .app-aside-dock .aside-wrap,
    .app-aside-dock .navi-wrap {
        float: none;
        width: 100% !important;
        position: relative;
        top: 0;
        overflow: visible !important;
    }

    .app-aside-dock .navi-wrap > * {
        width: auto !important;
    }

    .app-aside-dock .app-aside {
        bottom: auto !important;
    }

    .app-aside-dock .app-aside.b-r {
        border-right-width: 0;
        border-bottom: 1px solid #dee5e7;
    }

    .app-aside-dock .app-aside:before {
        display: none;
    }

    .app-aside-dock .app-aside nav > .nav {
        float: left;
    }

    .app-aside-dock .app-aside .hidden-folded,
    .app-aside-dock .app-aside .line,
    .app-aside-dock .app-aside .navi-wrap > div {
        display: none !important;
    }

    .app-aside-dock .app-aside .navi > ul > li {
        position: relative;
        float: left;
        display: inline-block;
    }

    .app-aside-dock .app-aside .navi > ul > li > a {
        padding: 10px 15px 12px 15px;
        text-align: center;
        height: auto;
    }

    .app-aside-dock .app-aside .navi > ul > li > a > .badge,
    .app-aside-dock .app-aside .navi > ul > li > a > .label {
        position: absolute;
        top: 5px;
        right: 8px;
        padding: 1px 4px;
    }

    .app-aside-dock .app-aside .navi > ul > li > a > i {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: -7px;
        margin-top: -10px;
        display: block;
        float: none;
        font-size: 14px;
        line-height: 40px;
        width: 40px;
    }

    .app-aside-dock .app-aside .navi > ul > li > a > span.pull-right {
        position: absolute;
        bottom: 2px;
        left: 50%;
        margin-left: -6px;
        display: block !important;
        line-height: 1;
    }

    .app-aside-dock .app-aside .navi > ul > li > a > span.pull-right i {
        line-height: 12px;
        width: 12px;
        font-size: 12px;
    }

    .app-aside-dock .app-aside .navi > ul > li > a > span.pull-right i.text {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        line-height: 14px;
    }

    .app-aside-dock .app-aside .navi > ul > li > a > span {
        font-weight: normal;
        display: block;
    }

    .app-aside-dock .app-aside .navi > ul > li .nav-sub {
        height: auto !important;
        display: none;
        position: absolute;
        left: 0;
        top: auto !important;
        z-index: 1050;
        width: 200px;
        -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }

    .app-aside-dock .app-aside .navi > ul > li .nav-sub-header {
        display: none !important;
    }

    .app-aside-dock .app-aside .navi li li a {
        padding-left: 15px;
    }

    .app-aside-dock .app-aside .navi li:hover > .nav-sub,
    .app-aside-dock .app-aside .navi li:focus > .nav-sub,
    .app-aside-dock .app-aside .navi li:active > .nav-sub {
        display: block;
        opacity: 1;
        margin-left: 0;
        height: auto !important;
        overflow: auto;
    }
}

.arrow {
    border-width: 9px;
    z-index: 10;
}

.arrow,
.arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.arrow:after {
    border-width: 8px;
    content: "";
}

.arrow.top {
    left: 50%;
    margin-left: -9px;
    border-top-width: 0;
    border-bottom-color: rgba(0, 0, 0, 0.1);
    top: -9px;
}

.arrow.top:after {
    top: 1px;
    margin-left: -8px;
    border-top-width: 0;
    border-bottom-color: #fff;
}

.arrow.top.arrow-primary:after {
    border-bottom-color: #333787;
}

.arrow.top.arrow-info:after {
    border-bottom-color: #23b7e5;
}

.arrow.top.arrow-success:after {
    border-bottom-color: #51c4cf;
}

.arrow.top.arrow-danger:after {
    border-bottom-color: #ff676d;
}

.arrow.top.arrow-warning:after {
    border-bottom-color: #868689;
}

.arrow.top.arrow-light:after {
    border-bottom-color: #edf1f2;
}

.arrow.top.arrow-dark:after {
    border-bottom-color: #474ba9;
}

.arrow.top.arrow-black:after {
    border-bottom-color: #51c4cf;
}

.arrow.right {
    top: 50%;
    right: -9px;
    margin-top: -9px;
    border-right-width: 0;
    border-left-color: rgba(0, 0, 0, 0.1);
}

.arrow.right:after {
    right: 1px;
    bottom: -8px;
    border-right-width: 0;
    border-left-color: #fff;
}

.arrow.right.arrow-primary:after {
    border-left-color: #333787;
}

.arrow.right.arrow-info:after {
    border-left-color: #23b7e5;
}

.arrow.right.arrow-success:after {
    border-left-color: #51c4cf;
}

.arrow.right.arrow-danger:after {
    border-left-color: #ff676d;
}

.arrow.right.arrow-warning:after {
    border-left-color: #868689;
}

.arrow.right.arrow-light:after {
    border-left-color: #edf1f2;
}

.arrow.right.arrow-dark:after {
    border-left-color: #474ba9;
}

.arrow.right.arrow-black:after {
    border-left-color: #51c4cf;
}

.arrow.bottom {
    left: 50%;
    bottom: -9px;
    margin-left: -9px;
    border-bottom-width: 0;
    border-top-color: rgba(0, 0, 0, 0.1);
}

.arrow.bottom:after {
    bottom: 1px;
    margin-left: -8px;
    border-bottom-width: 0;
    border-top-color: #fff;
}

.arrow.bottom.arrow-primary:after {
    border-top-color: #333787;
}

.arrow.bottom.arrow-info:after {
    border-top-color: #23b7e5;
}

.arrow.bottom.arrow-success:after {
    border-top-color: #51c4cf;
}

.arrow.bottom.arrow-danger:after {
    border-top-color: #ff676d;
}

.arrow.bottom.arrow-warning:after {
    border-top-color: #868689;
}

.arrow.bottom.arrow-light:after {
    border-top-color: #edf1f2;
}

.arrow.bottom.arrow-dark:after {
    border-top-color: #474ba9;
}

.arrow.bottom.arrow-black:after {
    border-top-color: #51c4cf;
}

.arrow.left {
    top: 50%;
    left: -9px;
    margin-top: -9px;
    border-left-width: 0;
    border-right-color: rgba(0, 0, 0, 0.1);
}

.arrow.left:after {
    left: 1px;
    bottom: -8px;
    border-left-width: 0;
    border-right-color: #fff;
}

.arrow.left.arrow-primary:after {
    border-right-color: #333787;
}

.arrow.left.arrow-info:after {
    border-right-color: #23b7e5;
}

.arrow.left.arrow-success:after {
    border-right-color: #51c4cf;
}

.arrow.left.arrow-danger:after {
    border-right-color: #ff676d;
}

.arrow.left.arrow-warning:after {
    border-right-color: #868689;
}

.arrow.left.arrow-light:after {
    border-right-color: #edf1f2;
}

.arrow.left.arrow-dark:after {
    border-right-color: #474ba9;
}

.arrow.left.arrow-black:after {
    border-right-color: #51c4cf;
}

.arrow.pull-left {
    left: 19px;
}

.arrow.pull-right {
    left: auto;
    right: 19px;
}

.arrow.pull-up {
    top: 19px;
}

.arrow.pull-down {
    top: auto;
    bottom: 19px;
}

.btn {
    font-weight: 500;
    border-radius: 5px;
    outline: 0 !important;
}

.btn-link {
    color: #868689;
}

.btn-link.active {
    webkit-box-shadow: none;
    box-shadow: none;
}

.btn-default {
    color: #868689 !important;
    background-color: #fcfdfd;
    border-color: #dee5e7;
    background-color: #fff;
    border-bottom-color: #d8e1e3;
    -webkit-box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
    box-shadow: 0 1px 1px rgba(90, 90, 90, 0.1);
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    color: #868689 !important;
    background-color: #edf1f2;
    border-color: #c7d3d6;
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
    background-color: #fcfdfd;
    border-color: #dee5e7;
}

.btn-default.btn-bg {
    border-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
}

.btn-primary {
    color: #fff !important;
    background-color: #333787;
    border-color: #333787;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    color: #fff !important;
    background-color: #2c2f74;
    border-color: #282b69;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
    color: #fff;
    background-color: #2c2f74;
    border-color: #282b69;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    background-color: #333787;
    border-color: #333787;
}

.btn-success {
    color: #fff !important;
    background-color: #51c4cf;
    border-color: #51c4cf;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
    color: #fff !important;
    background-color: #3dbdc9;
    border-color: #35b6c2;
}

.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
    background-image: none;
}

.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
    background-color: #51c4cf;
    border-color: #51c4cf;
}

.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
    color: #fff;
    background-color: #51c4cf;
    border-color: #51c4cf;
}

.btn-info {
    color: #fff !important;
    background-color: #23b7e5;
    border-color: #23b7e5;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
    color: #fff !important;
    background-color: #19a9d5;
    border-color: #189ec8;
}

.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
    background-image: none;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
    background-color: #23b7e5;
    border-color: #23b7e5;
}

.btn-warning {
    color: #fff !important;
    background-color: #868689;
    border-color: #868689;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
    color: #fff !important;
    background-color: #79797c;
    border-color: #727275;
}

.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
    background-image: none;
}

.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
    background-color: #868689;
    border-color: #868689;
}

.btn-danger {
    color: #fff !important;
    background-color: #ff676d;
    border-color: #ff676d;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
    color: #fff !important;
    background-color: #ff4d55;
    border-color: #ff3e46;
}

.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
    background-image: none;
}

.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
    background-color: #ff676d;
    border-color: #ff676d;
}

.btn-dark {
    color: #fff !important;
    background-color: #474ba9;
    border-color: #474ba9;
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
    color: #fff !important;
    background-color: #3f4397;
    border-color: #3b3e8c;
}

.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
    background-image: none;
}

.btn-dark.disabled,
.btn-dark[disabled],
fieldset[disabled] .btn-dark,
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled] .btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled] .btn-dark:focus,
.btn-dark.disabled:active,
.btn-dark[disabled]:active,
fieldset[disabled] .btn-dark:active,
.btn-dark.disabled.active,
.btn-dark[disabled].active,
fieldset[disabled] .btn-dark.active {
    background-color: #474ba9;
    border-color: #474ba9;
}

.btn-black {
    color: #fff !important;
    background-color: #51c4cf;
    border-color: #51c4cf;
}

.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
    color: #fff !important;
    background-color: #3dbdc9;
    border-color: #35b6c2;
}

.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
    background-image: none;
}

.btn-black.disabled,
.btn-black[disabled],
fieldset[disabled] .btn-black,
.btn-black.disabled:hover,
.btn-black[disabled]:hover,
fieldset[disabled] .btn-black:hover,
.btn-black.disabled:focus,
.btn-black[disabled]:focus,
fieldset[disabled] .btn-black:focus,
.btn-black.disabled:active,
.btn-black[disabled]:active,
fieldset[disabled] .btn-black:active,
.btn-black.disabled.active,
.btn-black[disabled].active,
fieldset[disabled] .btn-black.active {
    background-color: #51c4cf;
    border-color: #51c4cf;
}

.btn-fitness {
    color: #fff !important;
    background-color: #23b7e5;
    border-color: #23b7e5;
}

.btn-fitness:hover,
.btn-fitness:focus,
.btn-fitness:active,
.btn-fitness.active,
.open .dropdown-toggle.btn-fitness {
    color: #fff !important;
    background-color: #19a9d5;
    border-color: #189ec8;
}

.btn-fitness:active,
.btn-fitness.active,
.open .dropdown-toggle.btn-fitness {
    background-image: none;
}

.btn-fitness.disabled,
.btn-fitness[disabled],
fieldset[disabled] .btn-fitness,
.btn-fitness.disabled:hover,
.btn-fitness[disabled]:hover,
fieldset[disabled] .btn-fitness:hover,
.btn-fitness.disabled:focus,
.btn-fitness[disabled]:focus,
fieldset[disabled] .btn-fitness:focus,
.btn-fitness.disabled:active,
.btn-fitness[disabled]:active,
fieldset[disabled] .btn-fitness:active,
.btn-fitness.disabled.active,
.btn-fitness[disabled].active,
fieldset[disabled] .btn-fitness.active {
    background-color: #23b7e5;
    border-color: #23b7e5;
}

.btn-beauty {
    color: #fff !important;
    background-color: #a0a0bf;
    border-color: #a0a0bf;
}

.btn-beauty:hover,
.btn-beauty:focus,
.btn-beauty:active,
.btn-beauty.active,
.open .dropdown-toggle.btn-beauty {
    color: #fff !important;
    background-color: #9191b5;
    border-color: #8888af;
}

.btn-beauty:active,
.btn-beauty.active,
.open .dropdown-toggle.btn-beauty {
    background-image: none;
}

.btn-beauty.disabled,
.btn-beauty[disabled],
fieldset[disabled] .btn-beauty,
.btn-beauty.disabled:hover,
.btn-beauty[disabled]:hover,
fieldset[disabled] .btn-beauty:hover,
.btn-beauty.disabled:focus,
.btn-beauty[disabled]:focus,
fieldset[disabled] .btn-beauty:focus,
.btn-beauty.disabled:active,
.btn-beauty[disabled]:active,
fieldset[disabled] .btn-beauty:active,
.btn-beauty.disabled.active,
.btn-beauty[disabled].active,
fieldset[disabled] .btn-beauty.active {
    background-color: #a0a0bf;
    border-color: #a0a0bf;
}

.btn-wellness {
    color: #fff !important;
    background-color: #f8c2a8;
    border-color: #f8c2a8;
}

.btn-wellness:hover,
.btn-wellness:focus,
.btn-wellness:active,
.btn-wellness.active,
.open .dropdown-toggle.btn-wellness {
    color: #fff !important;
    background-color: #f6b190;
    border-color: #f5a882;
}

.btn-wellness:active,
.btn-wellness.active,
.open .dropdown-toggle.btn-wellness {
    background-image: none;
}

.btn-wellness.disabled,
.btn-wellness[disabled],
fieldset[disabled] .btn-wellness,
.btn-wellness.disabled:hover,
.btn-wellness[disabled]:hover,
fieldset[disabled] .btn-wellness:hover,
.btn-wellness.disabled:focus,
.btn-wellness[disabled]:focus,
fieldset[disabled] .btn-wellness:focus,
.btn-wellness.disabled:active,
.btn-wellness[disabled]:active,
fieldset[disabled] .btn-wellness:active,
.btn-wellness.disabled.active,
.btn-wellness[disabled].active,
fieldset[disabled] .btn-wellness.active {
    background-color: #f8c2a8;
    border-color: #f8c2a8;
}

.btn-healthy {
    color: #474ba9 !important;
    background-color: #bae4e7;
    border-color: #bae4e7;
}

.btn-healthy:hover,
.btn-healthy:focus,
.btn-healthy:active,
.btn-healthy.active,
.open .dropdown-toggle.btn-healthy {
    color: #474ba9 !important;
    background-color: #a7dde0;
    border-color: #9cd8dc;
}

.btn-healthy:active,
.btn-healthy.active,
.open .dropdown-toggle.btn-healthy {
    background-image: none;
}

.btn-healthy.disabled,
.btn-healthy[disabled],
fieldset[disabled] .btn-healthy,
.btn-healthy.disabled:hover,
.btn-healthy[disabled]:hover,
fieldset[disabled] .btn-healthy:hover,
.btn-healthy.disabled:focus,
.btn-healthy[disabled]:focus,
fieldset[disabled] .btn-healthy:focus,
.btn-healthy.disabled:active,
.btn-healthy[disabled]:active,
fieldset[disabled] .btn-healthy:active,
.btn-healthy.disabled.active,
.btn-healthy[disabled].active,
fieldset[disabled] .btn-healthy.active {
    background-color: #bae4e7;
    border-color: #bae4e7;
}

.btn-class {
    color: #fff !important;
    background-color: #83a0b1;
    border: none;
    box-shadow: none;
}

.btn-class:hover,
.btn-class:focus,
.btn-class:active,
.btn-class.active,
.open .dropdown-toggle.btn-class {
    color: #fff !important;
    background-color: #7394a7;
    border: none;
    box-shadow: none;
}

.btn-class:active,
.btn-class.active,
.open .dropdown-toggle.btn-class {
    background-image: none;
}

.btn-class.disabled,
.btn-class[disabled],
fieldset[disabled] .btn-class,
.btn-class.disabled:hover,
.btn-class[disabled]:hover,
fieldset[disabled] .btn-class:hover,
.btn-class.disabled:focus,
.btn-class[disabled]:focus,
fieldset[disabled] .btn-class:focus,
.btn-class.disabled:active,
.btn-class[disabled]:active,
fieldset[disabled] .btn-class:active,
.btn-class.disabled.active,
.btn-class[disabled].active,
fieldset[disabled] .btn-class.active {
    background-color: #83a0b1;
    border-color: #83a0b1;
}

.btn-appointment {
    color: #fff !important;
    background-color: #7ab2dc;
    border: none;
    box-shadow: none;
}

.btn-appointment:hover,
.btn-appointment:focus,
.btn-appointment:active,
.btn-appointment.active,
.open .dropdown-toggle.btn-appointment {
    color: #fff !important;
    background-color: #66a6d7;
    border: none;
    box-shadow: none;
}

.btn-appointment:active,
.btn-appointment.active,
.open .dropdown-toggle.btn-appointment {
    background-image: none;
}

.btn-appointment.disabled,
.btn-appointment[disabled],
fieldset[disabled] .btn-appointment,
.btn-appointment.disabled:hover,
.btn-appointment[disabled]:hover,
fieldset[disabled] .btn-appointment:hover,
.btn-appointment.disabled:focus,
.btn-appointment[disabled]:focus,
fieldset[disabled] .btn-appointment:focus,
.btn-appointment.disabled:active,
.btn-appointment[disabled]:active,
fieldset[disabled] .btn-appointment:active,
.btn-appointment.disabled.active,
.btn-appointment[disabled].active,
fieldset[disabled] .btn-appointment.active {
    border-color: #7ab2dc;
}

.btn-my-schedule {
    color: #474ba9 !important;
    background-color: #f2f4f8;
    border-color: #f2f4f8;
}

.btn-my-schedule:hover,
.btn-my-schedule:focus,
.btn-my-schedule:active,
.btn-my-schedule.active,
.open .dropdown-toggle.btn-my-schedule {
    color: #474ba9 !important;
    background-color: #e1e6ef;
    border-color: #d7deea;
}

.btn-my-schedule:active,
.btn-my-schedule.active,
.open .dropdown-toggle.btn-my-schedule {
    background-image: none;
}

.btn-my-schedule.disabled,
.btn-my-schedule[disabled],
fieldset[disabled] .btn-my-schedule,
.btn-my-schedule.disabled:hover,
.btn-my-schedule[disabled]:hover,
fieldset[disabled] .btn-my-schedule:hover,
.btn-my-schedule.disabled:focus,
.btn-my-schedule[disabled]:focus,
fieldset[disabled] .btn-my-schedule:focus,
.btn-my-schedule.disabled:active,
.btn-my-schedule[disabled]:active,
fieldset[disabled] .btn-my-schedule:active,
.btn-my-schedule.disabled.active,
.btn-my-schedule[disabled].active,
fieldset[disabled] .btn-my-schedule.active {
    background-color: #f2f4f8;
    border-color: #f2f4f8;
}

.btn-publish {
    color: #fff !important;
    background-color: #7ab2dc;
    border-color: #7ab2dc;
}

.btn-publish:hover,
.btn-publish:focus,
.btn-publish:active,
.btn-publish.active,
.open .dropdown-toggle.btn-publish {
    color: #fff !important;
    background-color: #66a6d7;
    border-color: #5a9fd4;
}

.btn-publish:active,
.btn-publish.active,
.open .dropdown-toggle.btn-publish {
    background-image: none;
}

.btn-publish.disabled,
.btn-publish[disabled],
fieldset[disabled] .btn-publish,
.btn-publish.disabled:hover,
.btn-publish[disabled]:hover,
fieldset[disabled] .btn-publish:hover,
.btn-publish.disabled:focus,
.btn-publish[disabled]:focus,
fieldset[disabled] .btn-publish:focus,
.btn-publish.disabled:active,
.btn-publish[disabled]:active,
fieldset[disabled] .btn-publish:active,
.btn-publish.disabled.active,
.btn-publish[disabled].active,
fieldset[disabled] .btn-publish.active {
    background-color: #7ab2dc;
    border-color: #7ab2dc;
}

.btn-facebook {
    color: #fff !important;
    background-color: #616aa8;
    border-color: #616aa8;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open .dropdown-toggle.btn-facebook {
    color: #fff !important;
    background-color: #555e9a;
    border-color: #505891;
}

.btn-facebook:active,
.btn-facebook.active,
.open .dropdown-toggle.btn-facebook {
    background-image: none;
}

.btn-facebook.disabled,
.btn-facebook[disabled],
fieldset[disabled] .btn-facebook,
.btn-facebook.disabled:hover,
.btn-facebook[disabled]:hover,
fieldset[disabled] .btn-facebook:hover,
.btn-facebook.disabled:focus,
.btn-facebook[disabled]:focus,
fieldset[disabled] .btn-facebook:focus,
.btn-facebook.disabled:active,
.btn-facebook[disabled]:active,
fieldset[disabled] .btn-facebook:active,
.btn-facebook.disabled.active,
.btn-facebook[disabled].active,
fieldset[disabled] .btn-facebook.active {
    background-color: #616aa8;
    border-color: #616aa8;
}

.btn-gray-light {
    color: #fff !important;
    background-color: #b4b6bd;
    border-color: #b4b6bd;
}

.btn-gray-light:hover,
.btn-gray-light:focus,
.btn-gray-light:active,
.btn-gray-light.active,
.open .dropdown-toggle.btn-gray-light {
    color: #fff !important;
    background-color: #a6a9b1;
    border-color: #9ea1aa;
}

.btn-gray-light:active,
.btn-gray-light.active,
.open .dropdown-toggle.btn-gray-light {
    background-image: none;
}

.btn-gray-light.disabled,
.btn-gray-light[disabled],
fieldset[disabled] .btn-gray-light,
.btn-gray-light.disabled:hover,
.btn-gray-light[disabled]:hover,
fieldset[disabled] .btn-gray-light:hover,
.btn-gray-light.disabled:focus,
.btn-gray-light[disabled]:focus,
fieldset[disabled] .btn-gray-light:focus,
.btn-gray-light.disabled:active,
.btn-gray-light[disabled]:active,
fieldset[disabled] .btn-gray-light:active,
.btn-gray-light.disabled.active,
.btn-gray-light[disabled].active,
fieldset[disabled] .btn-gray-light.active {
    background-color: #b4b6bd;
    border-color: #b4b6bd;
}

.btn-dusty-blue {
    color: #fff !important;
    background-color: #6164ad;
    border-color: #6164ad;
}

.btn-dusty-blue:hover,
.btn-dusty-blue:focus,
.btn-dusty-blue:active,
.btn-dusty-blue.active,
.open .dropdown-toggle.btn-dusty-blue {
    color: #fff !important;
    background-color: #5457a1;
    border-color: #4e5197;
}

.btn-dusty-blue:active,
.btn-dusty-blue.active,
.open .dropdown-toggle.btn-dusty-blue {
    background-image: none;
}

.btn-dusty-blue.disabled,
.btn-dusty-blue[disabled],
fieldset[disabled] .btn-dusty-blue,
.btn-dusty-blue.disabled:hover,
.btn-dusty-blue[disabled]:hover,
fieldset[disabled] .btn-dusty-blue:hover,
.btn-dusty-blue.disabled:focus,
.btn-dusty-blue[disabled]:focus,
fieldset[disabled] .btn-dusty-blue:focus,
.btn-dusty-blue.disabled:active,
.btn-dusty-blue[disabled]:active,
fieldset[disabled] .btn-dusty-blue:active,
.btn-dusty-blue.disabled.active,
.btn-dusty-blue[disabled].active,
fieldset[disabled] .btn-dusty-blue.active {
    background-color: #6164ad;
    border-color: #6164ad;
}

.btn-events {
    color: #6164ad !important;
    background-color: #dee5e7;
    border-color: #dee5e7;
}

.btn-events:hover,
.btn-events:focus,
.btn-events:active,
.btn-events.active,
.open .dropdown-toggle.btn-events {
    color: #6164ad !important;
    background-color: #cfd9dc;
    border-color: #c6d2d6;
}

.btn-events:active,
.btn-events.active,
.open .dropdown-toggle.btn-events {
    background-image: none;
}

.btn-events.disabled,
.btn-events[disabled],
fieldset[disabled] .btn-events,
.btn-events.disabled:hover,
.btn-events[disabled]:hover,
fieldset[disabled] .btn-events:hover,
.btn-events.disabled:focus,
.btn-events[disabled]:focus,
fieldset[disabled] .btn-events:focus,
.btn-events.disabled:active,
.btn-events[disabled]:active,
fieldset[disabled] .btn-events:active,
.btn-events.disabled.active,
.btn-events[disabled].active,
fieldset[disabled] .btn-events.active {
    background-color: #dee5e7;
    border-color: #dee5e7;
}

.btn-icon {
    padding: 0 !important;
    text-align: center;
    width: 34px;
    height: 34px;
}

.btn-icon i {
    top: -1px;
    position: relative;
    line-height: 34px;
}

.btn-icon.btn-sm {
    width: 30px;
    height: 30px;
}

.btn-icon.btn-sm i {
    line-height: 30px;
}

.btn-icon.btn-lg {
    width: 45px;
    height: 45px;
}

.btn-icon.btn-lg i {
    line-height: 45px;
}

.btn-rounded {
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
}

.btn-rounded.btn-lg {
    padding-left: 25px;
    padding-right: 25px;
}

.btn > i.pull-left,
.btn > i.pull-right {
    line-height: 1.42857143;
}

.btn-block {
    padding-left: 12px;
    padding-right: 12px;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
    border-top-right-radius: 5px;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
    border-bottom-left-radius: 5px;
}

.btn-addon i {
    margin: -7px -12px;
    margin-right: 12px;
    background-color: rgba(0, 0, 0, 0.1);
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    float: left;
    position: relative;
    border-radius: 5px 0 0 5px;
}

.btn-addon i.pull-right {
    margin-right: -12px;
    margin-left: 12px;
    border-radius: 0 5px 5px 0;
}

.btn-addon.btn-sm i {
    margin: -6px -10px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.btn-addon.btn-sm i.pull-right {
    margin-right: -10px;
    margin-left: 10px;
}

.btn-addon.btn-lg i {
    margin: -11px -16px;
    margin-right: 16px;
    width: 45px;
    height: 45px;
    line-height: 45px;
}

.btn-addon.btn-lg i.pull-right {
    margin-right: -16px;
    margin-left: 16px;
}

.btn-addon.btn-default i {
    background-color: transparent;
    border-right: 1px solid #dee5e7;
}

.btn-groups .btn {
    margin-bottom: 5px;
}

.list-icon i {
    width: 40px;
    vertical-align: middle;
    margin: 0;
    display: inline-block;
    text-align: center;
    -webkit-transition: font-size 0.2s;
    transition: font-size 0.2s;
}

.list-icon div {
    line-height: 40px;
    white-space: nowrap;
}

.list-icon div:hover i {
    font-size: 26px;
}

.settings {
    z-index: 1050;
    position: fixed;
    top: 120px;
    right: -240px;
    width: 240px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.settings.active {
    right: -1px;
}

.settings > .btn {
    background: #f6f8f8 !important;
    border-right-width: 0;
    border-color: #dee5e7;
    position: absolute;
    left: -42px;
    top: -1px;
    padding: 10px 15px;
}

.settings .i-checks span b {
    width: 50%;
    height: 20px;
    display: inline-block;
    float: left;
}

.settings .i-checks span b.header {
    height: 10px;
}

.streamline {
    position: relative;
    border-color: #dee5e7;
}

.streamline .sl-item:after,
.streamline:after {
    content: '';
    position: absolute;
    background-color: #fff;
    border-color: inherit;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    width: 9px;
    height: 9px;
    margin-left: -5px;
    bottom: 0;
    left: 0;
}

.sl-item {
    border-color: #dee5e7;
    position: relative;
    padding-bottom: 1px;
}

.sl-item:before,
.sl-item:after {
    content: " ";
    display: table;
}

.sl-item:after {
    clear: both;
}

.sl-item:after {
    top: 6px;
    bottom: auto;
}

.sl-item.b-l {
    margin-left: -1px;
}

.timeline {
    margin: 0;
    padding: 0;
}

.tl-item {
    display: block;
}

.tl-item:before,
.tl-item:after {
    content: " ";
    display: table;
}

.tl-item:after {
    clear: both;
}

.visible-left {
    display: none;
}

.tl-wrap {
    display: block;
    margin-left: 6em;
    padding: 15px 0 15px 20px;
    border-style: solid;
    border-color: #dee5e7;
    border-width: 0 0 0 4px;
}

.tl-wrap:before,
.tl-wrap:after {
    content: " ";
    display: table;
}

.tl-wrap:after {
    clear: both;
}

.tl-wrap:before {
    position: relative;
    content: "";
    float: left;
    top: 15px;
    margin-left: -27px;
    width: 10px;
    height: 10px;
    border-color: inherit;
    border-width: 3px;
    border-radius: 50%;
    border-style: solid;
    background: #edf1f2;
    box-shadow: 0 0 0 4px #f0f3f4;
}

.tl-wrap:hover:before {
    background: transparent;
    border-color: #fff;
}

.tl-date {
    position: relative;
    top: 10px;
    float: left;
    margin-left: -7.5em;
    display: block;
    width: 4.5em;
    text-align: right;
}

.tl-content {
    display: inline-block;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
}

.tl-content.block {
    display: block;
    width: 100%;
}

.tl-content.panel {
    margin-bottom: 0;
}

.tl-header {
    display: block;
    width: 12em;
    text-align: center;
    margin-left: 2px;
    margin-right: 2px;
}

.timeline-center .tl-item {
    margin-left: 50%;
}

.timeline-center .tl-item .tl-wrap {
    margin-left: -2px;
}

.timeline-center .tl-header {
    width: auto;
    margin: 0;
}

.timeline-center .tl-left {
    margin-left: 0;
    margin-right: 50%;
}

.timeline-center .tl-left .hidden-left {
    display: none !important;
}

.timeline-center .tl-left .visible-left {
    display: inherit;
}

.timeline-center .tl-left .tl-wrap {
    float: right;
    margin-right: -2px;
    border-left-width: 0;
    border-right-width: 4px;
    padding-left: 0;
    padding-right: 20px;
}

.timeline-center .tl-left .tl-wrap:before {
    float: right;
    margin-left: 0;
    margin-right: -27px;
}

.timeline-center .tl-left .tl-date {
    float: right;
    margin-left: 0;
    margin-right: -8.5em;
    text-align: left;
}

.i-switch {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
    border-radius: 30px;
    background-color: #51c4cf;
    margin: 0;
}

.i-switch input {
    position: absolute;
    opacity: 0;
    filter: alpha(opacity=0);
}

.i-switch input:checked + i:before {
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 5px;
    border-width: 0;
    border-radius: 5px;
}

.i-switch input:checked + i:after {
    margin-left: 16px;
}

.i-switch i:before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 30px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.i-switch i:after {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 18px;
    top: 1px;
    bottom: 1px;
    border-radius: 50%;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    -webkit-transition: margin-left 0.3s;
    transition: margin-left 0.3s;
}

.i-switch-md {
    width: 40px;
    height: 24px;
}

.i-switch-md input:checked + i:after {
    margin-left: 17px;
}

.i-switch-md i:after {
    width: 22px;
}

.i-switch-lg {
    width: 50px;
    height: 30px;
}

.i-switch-lg input:checked + i:after {
    margin-left: 21px;
}

.i-switch-lg i:after {
    width: 28px;
}

.i-checks {
    padding-left: 20px;
    cursor: pointer;
}

.i-checks input {
    opacity: 0;
    position: absolute;
    margin-left: -20px;
}

.i-checks input:checked + i {
    border-color: #23b7e5;
}

.i-checks input:checked + i:before {
    left: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    background-color: #23b7e5;
}

.i-checks input:checked + span .active {
    display: inherit;
}

.i-checks input[type="radio"] + i,
.i-checks input[type="radio"] + i:before {
    border-radius: 50%;
}

.i-checks input[disabled] + i,
fieldset[disabled] .i-checks input + i {
    border-color: #dee5e7;
}

.i-checks input[disabled] + i:before,
fieldset[disabled] .i-checks input + i:before {
    background-color: #dee5e7;
}

.i-checks > i {
    width: 20px;
    height: 20px;
    line-height: 1;
    border: 1px solid #cfdadd;
    background-color: #fff;
    margin-left: -20px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    position: relative;
}

.i-checks > i:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0px;
    height: 0px;
    background-color: transparent;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.i-checks > span {
    margin-left: -20px;
}

.i-checks > span .active {
    display: none;
}

.i-checks-sm input:checked + i:before {
    left: 3px;
    top: 3px;
    width: 8px;
    height: 8px;
}

.i-checks-sm > i {
    width: 16px;
    height: 16px;
    margin-left: -18px;
    margin-right: 6px;
}

.i-checks-lg input:checked + i:before {
    left: 8px;
    top: 8px;
    width: 12px;
    height: 12px;
}

.i-checks-lg > i {
    width: 30px;
    height: 30px;
}

.datepicker {
    margin: 0 5px;
}

.datepicker .btn-default {
    border-width: 0;
    box-shadow: none;
}

.datepicker .btn[disabled] {
    opacity: 0.4;
}

.datepicker .btn-info .text-info {
    color: #fff !important;
}

/*Charts*/
.jqstooltip {
    background-color: rgba(0, 0, 0, 0.8) !important;
    border: solid 1px #000 !important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 5px 10px !important;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    max-height: 12px;
}

.easyPieChart {
    position: relative;
    text-align: center;
}

.easyPieChart > div {
    position: relative;
    z-index: 1;
}

.easyPieChart > div .text {
    position: absolute;
    width: 100%;
    top: 60%;
    line-height: 1;
}

.easyPieChart > div img {
    margin-top: -4px;
}

.easyPieChart canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

#flotTip,
.flotTip {
    padding: 4px 10px;
    background-color: rgba(0, 0, 0, 0.8);
    border: solid 1px #000 !important;
    z-index: 100;
    font-size: 12px;
    color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.legendColorBox > div {
    border: none !important;
    margin: 5px;
}

.legendColorBox > div > div {
    border-radius: 10px;
}

.sortable-placeholder {
    list-style: none;
    border: 1px dashed #CCC;
    min-height: 50px;
    margin-bottom: 5px;
}

.panel .dataTables_wrapper {
    padding-top: 10px;
}

.panel .dataTables_wrapper > .row {
    margin: 0;
}

.panel .dataTables_wrapper > .row > .col-sm-12 {
    padding: 0;
}

.st-sort-ascent:before {
    content: '\25B2';
}

.st-sort-descent:before {
    content: '\25BC';
}

.st-selected td {
    background: #f0f9ec !important;
}

.chosen-choices,
.chosen-single,
.bootstrap-tagsinput {
    border-radius: 2px !important;
    border-color: #cfdadd !important;
}

.bootstrap-tagsinput {
    padding: 5px 12px !important;
}

.item {
    position: relative;
}

.item .top {
    position: absolute;
    top: 0;
    left: 0;
}

.item .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
}

.item .center {
    position: absolute;
    top: 50%;
}

.item-overlay {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.item-overlay.active,
.item:hover .item-overlay {
    display: block;
}

.form-validation .form-control.ng-dirty.ng-invalid {
    border-color: #ff676d;
}

.form-validation .form-control.ng-dirty.ng-valid,
.form-validation .form-control.ng-dirty.ng-valid:focus {
    border-color: #51c4cf;
}

.form-validation .i-checks .ng-invalid.ng-dirty + i {
    border-color: #ff676d;
}

.ng-animate .bg-auto:before {
    display: none;
}

[ui-view].ng-leave {
    display: none;
}

[ui-view].ng-leave.smooth {
    display: block;
}

.smooth.ng-animate {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.fade-in-right-big.ng-enter {
    -webkit-animation: fadeInRightBig 0.5s;
    animation: fadeInRightBig 0.5s;
}

.fade-in-right-big.ng-leave {
    -webkit-animation: fadeOutLeftBig 0.5s;
    animation: fadeOutLeftBig 0.5s;
}

.fade-in-left-big.ng-enter {
    -webkit-animation: fadeInLeftBig 0.5s;
    animation: fadeInLeftBig 0.5s;
}

.fade-in-left-big.ng-leave {
    -webkit-animation: fadeOutRightBig 0.5s;
    animation: fadeOutRightBig 0.5s;
}

.fade-in-up-big.ng-enter {
    -webkit-animation: fadeInUpBig 0.5s;
    animation: fadeInUpBig 0.5s;
}

.fade-in-up-big.ng-leave {
    -webkit-animation: fadeOutUpBig 0.5s;
    animation: fadeOutUpBig 0.5s;
}

.fade-in-down-big.ng-enter {
    -webkit-animation: fadeInDownBig 0.5s;
    animation: fadeInDownBig 0.5s;
}

.fade-in-down-big.ng-leave {
    -webkit-animation: fadeOutDownBig 0.5s;
    animation: fadeOutDownBig 0.5s;
}

.fade-in.ng-enter {
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;
}

.fade-in.ng-leave {
    -webkit-animation: fadeOut 0.5s;
    animation: fadeOut 0.5s;
}

.fade-in-right.ng-enter {
    -webkit-animation: fadeInRight 0.5s;
    animation: fadeInRight 0.5s;
}

.fade-in-right.ng-leave {
    -webkit-animation: fadeOutLeft 0.5s;
    animation: fadeOutLeft 0.5s;
}

.fade-in-left.ng-enter {
    -webkit-animation: fadeInLeft 0.5s;
    animation: fadeInLeft 0.5s;
}

.fade-in-left.ng-leave {
    -webkit-animation: fadeOutRight 0.5s;
    animation: fadeOutRight 0.5s;
}

.fade-in-up.ng-enter {
    -webkit-animation: fadeInUp 0.5s;
    animation: fadeInUp 0.5s;
}

.fade-in-up.ng-leave {
    -webkit-animation: fadeOutUp 0.5s;
    animation: fadeOutUp 0.5s;
}

.fade-in-down.ng-enter {
    -webkit-animation: fadeInDown 0.5s;
    animation: fadeInDown 0.5s;
}

.fade-in-down.ng-leave {
    -webkit-animation: fadeOutDown 0.5s;
    animation: fadeOutDown 0.5s;
}

.bg-gd {
    background-image: -webkit-linear-gradient(top, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
    background-image: -o-linear-gradient(top, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
    background-image: linear-gradient(to bottom, rgba(40, 50, 60, 0) 0, rgba(40, 50, 60, 0.075) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#1328323c', GradientType=0);
    filter: none;
}

.bg-gd-dk {
    background-image: -webkit-linear-gradient(top, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
    background-image: -o-linear-gradient(top, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
    background-image: linear-gradient(to bottom, rgba(40, 50, 60, 0) 10%, rgba(40, 50, 60, 0.5) 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0028323c', endColorstr='#8028323c', GradientType=0);
    filter: none;
}

.bg-light {
    background-color: #edf1f2;
    color: #868689;
}

.bg-light.lt,
.bg-light .lt {
    background-color: #f3f5f6;
}

.bg-light.lter,
.bg-light .lter {
    background-color: #f6f8f8;
}

.bg-light.dk,
.bg-light .dk {
    background-color: #e4eaec;
}

.bg-light.dker,
.bg-light .dker {
    background-color: #dde6e9;
}

.bg-light.bg,
.bg-light .bg {
    background-color: #edf1f2;
}

.bg-dark {
    background-color: #474ba9;
    color: #d4d5e8;
}

.bg-dark.lt,
.bg-dark .lt {
    background-color: #565ab4;
}

.bg-dark.lter,
.bg-dark .lter {
    background-color: #6a6db9;
}

.bg-dark.dk,
.bg-dark .dk {
    background-color: #3d419a;
}

.bg-dark.dker,
.bg-dark .dker {
    background-color: #33378a;
}

.bg-dark.bg,
.bg-dark .bg {
    background-color: #474ba9;
}

.bg-dark a {
    color: #f5f5fa;
}

.bg-dark a:hover {
    color: #fff;
}

.bg-dark a.list-group-item:hover,
.bg-dark a.list-group-item:focus {
    background-color: inherit;
}

.bg-dark .nav > li:hover > a,
.bg-dark .nav > li:focus > a,
.bg-dark .nav > li.active > a {
    color: #fff;
    background-color: #3d419a;
}

.bg-dark .nav > li > a {
    color: #e5e5f1;
}

.bg-dark .nav > li > a:hover,
.bg-dark .nav > li > a:focus {
    background-color: #4044a1;
}

.bg-dark .nav .open > a {
    background-color: #3d419a;
}

.bg-dark .caret {
    border-top-color: #d4d5e8;
    border-bottom-color: #d4d5e8;
}

.bg-dark.navbar .nav > li.active > a {
    color: #fff;
    background-color: #3d419a;
}

.bg-dark .open > a,
.bg-dark .open > a:hover,
.bg-dark .open > a:focus {
    color: #fff;
}

.bg-dark .text-muted {
    color: #b2b4d7 !important;
}

.bg-dark .text-lt {
    color: #ffffff !important;
}

.bg-dark.auto .list-group-item,
.bg-dark .auto .list-group-item {
    border-color: #3f4397 !important;
    background-color: transparent;
}

.bg-dark.auto .list-group-item:hover,
.bg-dark .auto .list-group-item:hover,
.bg-dark.auto .list-group-item:focus,
.bg-dark .auto .list-group-item:focus,
.bg-dark.auto .list-group-item:active,
.bg-dark .auto .list-group-item:active,
.bg-dark.auto .list-group-item.active,
.bg-dark .auto .list-group-item.active {
    background-color: #3d419a !important;
}

.bg-black {
    background-color: #51c4cf;
    color: #f2f9fa;
}

.bg-black.lt,
.bg-black .lt {
    background-color: #67c9d2;
}

.bg-black.lter,
.bg-black .lter {
    background-color: #7dced6;
}

.bg-black.dk,
.bg-black .dk {
    background-color: #3ac0cd;
}

.bg-black.dker,
.bg-black .dker {
    background-color: #2db3c0;
}

.bg-black.bg,
.bg-black .bg {
    background-color: #51c4cf;
}

.bg-black a {
    color: #ffffff;
}

.bg-black a:hover {
    color: #fff;
}

.bg-black a.list-group-item:hover,
.bg-black a.list-group-item:focus {
    background-color: inherit;
}

.bg-black .nav > li:hover > a,
.bg-black .nav > li:focus > a,
.bg-black .nav > li.active > a {
    color: #fff;
    background-color: #3ac0cd;
}

.bg-black .nav > li > a {
    color: #f2f2f2;
}

.bg-black .nav > li > a:hover,
.bg-black .nav > li > a:focus {
    background-color: #42c2cf;
}

.bg-black .nav .open > a {
    background-color: #3ac0cd;
}

.bg-black .caret {
    border-top-color: #f2f9fa;
    border-bottom-color: #f2f9fa;
}

.bg-black.navbar .nav > li.active > a {
    color: #fff;
    background-color: #3ac0cd;
}

.bg-black .open > a,
.bg-black .open > a:hover,
.bg-black .open > a:focus {
    color: #fff;
}

.bg-black .text-muted {
    color: #cceaed !important;
}

.bg-black .text-lt {
    color: #ffffff !important;
}

.bg-black.auto .list-group-item,
.bg-black .auto .list-group-item {
    border-color: #3dbdc9 !important;
    background-color: transparent;
}

.bg-black.auto .list-group-item:hover,
.bg-black .auto .list-group-item:hover,
.bg-black.auto .list-group-item:focus,
.bg-black .auto .list-group-item:focus,
.bg-black.auto .list-group-item:active,
.bg-black .auto .list-group-item:active,
.bg-black.auto .list-group-item.active,
.bg-black .auto .list-group-item.active {
    background-color: #3ac0cd !important;
}

.bg-primary {
    background-color: #333787;
    color: #aeb0d8;
}

.bg-primary.lt,
.bg-primary .lt {
    background-color: #3d4197;
}

.bg-primary.lter,
.bg-primary .lter {
    background-color: #474ba6;
}

.bg-primary.dk,
.bg-primary .dk {
    background-color: #2a2e76;
}

.bg-primary.dker,
.bg-primary .dker {
    background-color: #222565;
}

.bg-primary.bg,
.bg-primary .bg {
    background-color: #333787;
}

.bg-primary a {
    color: #d0d2e9;
}

.bg-primary a:hover {
    color: #fff;
}

.bg-primary a.list-group-item:hover,
.bg-primary a.list-group-item:focus {
    background-color: inherit;
}

.bg-primary .nav > li:hover > a,
.bg-primary .nav > li:focus > a,
.bg-primary .nav > li.active > a {
    color: #fff;
    background-color: #2a2e76;
}

.bg-primary .nav > li > a {
    color: #bfc1e0;
}

.bg-primary .nav > li > a:hover,
.bg-primary .nav > li > a:focus {
    background-color: #2d317e;
}

.bg-primary .nav .open > a {
    background-color: #2a2e76;
}

.bg-primary .caret {
    border-top-color: #aeb0d8;
    border-bottom-color: #aeb0d8;
}

.bg-primary.navbar .nav > li.active > a {
    color: #fff;
    background-color: #2a2e76;
}

.bg-primary .open > a,
.bg-primary .open > a:hover,
.bg-primary .open > a:focus {
    color: #fff;
}

.bg-primary .text-muted {
    color: #8b8ec8 !important;
}

.bg-primary .text-lt {
    color: #ffffff !important;
}

.bg-primary.auto .list-group-item,
.bg-primary .auto .list-group-item {
    border-color: #2c2f74 !important;
    background-color: transparent;
}

.bg-primary.auto .list-group-item:hover,
.bg-primary .auto .list-group-item:hover,
.bg-primary.auto .list-group-item:focus,
.bg-primary .auto .list-group-item:focus,
.bg-primary.auto .list-group-item:active,
.bg-primary .auto .list-group-item:active,
.bg-primary.auto .list-group-item.active,
.bg-primary .auto .list-group-item.active {
    background-color: #2a2e76 !important;
}

.bg-success {
    background-color: #51c4cf;
    color: #f2f9fa;
}

.bg-success.lt,
.bg-success .lt {
    background-color: #67c9d2;
}

.bg-success.lter,
.bg-success .lter {
    background-color: #7dced6;
}

.bg-success.dk,
.bg-success .dk {
    background-color: #3ac0cd;
}

.bg-success.dker,
.bg-success .dker {
    background-color: #2db3c0;
}

.bg-success.bg,
.bg-success .bg {
    background-color: #51c4cf;
}

.bg-success a {
    color: #ffffff;
}

.bg-success a:hover {
    color: #fff;
}

.bg-success a.list-group-item:hover,
.bg-success a.list-group-item:focus {
    background-color: inherit;
}

.bg-success .nav > li:hover > a,
.bg-success .nav > li:focus > a,
.bg-success .nav > li.active > a {
    color: #fff;
    background-color: #3ac0cd;
}

.bg-success .nav > li > a {
    color: #f2f2f2;
}

.bg-success .nav > li > a:hover,
.bg-success .nav > li > a:focus {
    background-color: #42c2cf;
}

.bg-success .nav .open > a {
    background-color: #3ac0cd;
}

.bg-success .caret {
    border-top-color: #f2f9fa;
    border-bottom-color: #f2f9fa;
}

.bg-success.navbar .nav > li.active > a {
    color: #fff;
    background-color: #3ac0cd;
}

.bg-success .open > a,
.bg-success .open > a:hover,
.bg-success .open > a:focus {
    color: #fff;
}

.bg-success .text-muted {
    color: #cceaed !important;
}

.bg-success .text-lt {
    color: #ffffff !important;
}

.bg-success.auto .list-group-item,
.bg-success .auto .list-group-item {
    border-color: #3dbdc9 !important;
    background-color: transparent;
}

.bg-success.auto .list-group-item:hover,
.bg-success .auto .list-group-item:hover,
.bg-success.auto .list-group-item:focus,
.bg-success .auto .list-group-item:focus,
.bg-success.auto .list-group-item:active,
.bg-success .auto .list-group-item:active,
.bg-success.auto .list-group-item.active,
.bg-success .auto .list-group-item.active {
    background-color: #3ac0cd !important;
}

.bg-info {
    background-color: #23b7e5;
    color: #dcf2f8;
}

.bg-info.lt,
.bg-info .lt {
    background-color: #3dbde5;
}

.bg-info.lter,
.bg-info .lter {
    background-color: #55c3e6;
}

.bg-info.dk,
.bg-info .dk {
    background-color: #16aad8;
}

.bg-info.dker,
.bg-info .dker {
    background-color: #1199c4;
}

.bg-info.bg,
.bg-info .bg {
    background-color: #23b7e5;
}

.bg-info a {
    color: #ffffff;
}

.bg-info a:hover {
    color: #fff;
}

.bg-info a.list-group-item:hover,
.bg-info a.list-group-item:focus {
    background-color: inherit;
}

.bg-info .nav > li:hover > a,
.bg-info .nav > li:focus > a,
.bg-info .nav > li.active > a {
    color: #fff;
    background-color: #16aad8;
}

.bg-info .nav > li > a {
    color: #f2f2f2;
}

.bg-info .nav > li > a:hover,
.bg-info .nav > li > a:focus {
    background-color: #17b2e2;
}

.bg-info .nav .open > a {
    background-color: #16aad8;
}

.bg-info .caret {
    border-top-color: #dcf2f8;
    border-bottom-color: #dcf2f8;
}

.bg-info.navbar .nav > li.active > a {
    color: #fff;
    background-color: #16aad8;
}

.bg-info .open > a,
.bg-info .open > a:hover,
.bg-info .open > a:focus {
    color: #fff;
}

.bg-info .text-muted {
    color: #b0e1f1 !important;
}

.bg-info .text-lt {
    color: #ffffff !important;
}

.bg-info.auto .list-group-item,
.bg-info .auto .list-group-item {
    border-color: #19a9d5 !important;
    background-color: transparent;
}

.bg-info.auto .list-group-item:hover,
.bg-info .auto .list-group-item:hover,
.bg-info.auto .list-group-item:focus,
.bg-info .auto .list-group-item:focus,
.bg-info.auto .list-group-item:active,
.bg-info .auto .list-group-item:active,
.bg-info.auto .list-group-item.active,
.bg-info .auto .list-group-item.active {
    background-color: #16aad8 !important;
}

.bg-warning {
    background-color: #868689;
    color: #eeeeee;
}

.bg-warning.lt,
.bg-warning .lt {
    background-color: #949494;
}

.bg-warning.lter,
.bg-warning .lter {
    background-color: #a1a1a1;
}

.bg-warning.dk,
.bg-warning .dk {
    background-color: #76767f;
}

.bg-warning.dker,
.bg-warning .dker {
    background-color: #676775;
}

.bg-warning.bg,
.bg-warning .bg {
    background-color: #868689;
}

.bg-warning a {
    color: #ffffff;
}

.bg-warning a:hover {
    color: #fff;
}

.bg-warning a.list-group-item:hover,
.bg-warning a.list-group-item:focus {
    background-color: inherit;
}

.bg-warning .nav > li:hover > a,
.bg-warning .nav > li:focus > a,
.bg-warning .nav > li.active > a {
    color: #fff;
    background-color: #76767f;
}

.bg-warning .nav > li > a {
    color: #e5e5ff;
}

.bg-warning .nav > li > a:hover,
.bg-warning .nav > li > a:focus {
    background-color: #7b7b85;
}

.bg-warning .nav .open > a {
    background-color: #76767f;
}

.bg-warning .caret {
    border-top-color: #eeeeee;
    border-bottom-color: #eeeeee;
}

.bg-warning.navbar .nav > li.active > a {
    color: #fff;
    background-color: #76767f;
}

.bg-warning .open > a,
.bg-warning .open > a:hover,
.bg-warning .open > a:focus {
    color: #fff;
}

.bg-warning .text-muted {
    color: #d4d4d4 !important;
}

.bg-warning .text-lt {
    color: #ffffff !important;
}

.bg-warning.auto .list-group-item,
.bg-warning .auto .list-group-item {
    border-color: #79797c !important;
    background-color: transparent;
}

.bg-warning.auto .list-group-item:hover,
.bg-warning .auto .list-group-item:hover,
.bg-warning.auto .list-group-item:focus,
.bg-warning .auto .list-group-item:focus,
.bg-warning.auto .list-group-item:active,
.bg-warning .auto .list-group-item:active,
.bg-warning.auto .list-group-item.active,
.bg-warning .auto .list-group-item.active {
    background-color: #76767f !important;
}

.bg-danger {
    background-color: #ff676d;
    color: #ffffff;
}

.bg-danger.lt,
.bg-danger .lt {
    background-color: #fd8287;
}

.bg-danger.lter,
.bg-danger .lter {
    background-color: #fc9da0;
}

.bg-danger.dk,
.bg-danger .dk {
    background-color: #ff4d55;
}

.bg-danger.dker,
.bg-danger .dker {
    background-color: #ff343c;
}

.bg-danger.bg,
.bg-danger .bg {
    background-color: #ff676d;
}

.bg-danger a {
    color: #ffffff;
}

.bg-danger a:hover {
    color: #fff;
}

.bg-danger a.list-group-item:hover,
.bg-danger a.list-group-item:focus {
    background-color: inherit;
}

.bg-danger .nav > li:hover > a,
.bg-danger .nav > li:focus > a,
.bg-danger .nav > li.active > a {
    color: #fff;
    background-color: #ff4d55;
}

.bg-danger .nav > li > a {
    color: #f2f2f2;
}

.bg-danger .nav > li > a:hover,
.bg-danger .nav > li > a:focus {
    background-color: #ff585e;
}

.bg-danger .nav .open > a {
    background-color: #ff4d55;
}

.bg-danger .caret {
    border-top-color: #ffffff;
    border-bottom-color: #ffffff;
}

.bg-danger.navbar .nav > li.active > a {
    color: #fff;
    background-color: #ff4d55;
}

.bg-danger .open > a,
.bg-danger .open > a:hover,
.bg-danger .open > a:focus {
    color: #fff;
}

.bg-danger .text-muted {
    color: #e6e6e6 !important;
}

.bg-danger .text-lt {
    color: #ffffff !important;
}

.bg-danger.auto .list-group-item,
.bg-danger .auto .list-group-item {
    border-color: #ff4d55 !important;
    background-color: transparent;
}

.bg-danger.auto .list-group-item:hover,
.bg-danger .auto .list-group-item:hover,
.bg-danger.auto .list-group-item:focus,
.bg-danger .auto .list-group-item:focus,
.bg-danger.auto .list-group-item:active,
.bg-danger .auto .list-group-item:active,
.bg-danger.auto .list-group-item.active,
.bg-danger .auto .list-group-item.active {
    background-color: #ff4d55 !important;
}

.bg-white {
    background-color: #fff;
    color: #868689;
}

.bg-white a {
    color: inherit;
}

.bg-white a:hover {
    color: inherit;
}

.bg-white .text-muted {
    color: #c7c7c8 !important;
}

.bg-white .lt,
.bg-white .lter,
.bg-white .dk,
.bg-white .dker {
    background-color: #fff;
}

.bg-white-only {
    background-color: #fff;
}

.bg-white-opacity {
    background-color: rgba(255, 255, 255, 0.5);
}

.bg-black-opacity {
    background-color: rgba(32, 43, 54, 0.5);
}

a.bg-light:hover {
    color: inherit;
}

a.bg-primary:hover {
    background-color: #2c2f74;
}

a.text-primary:hover {
    color: #2c2f74;
}

.text-primary {
    color: #333787;
}

.text-primary-lt {
    color: #3a3f9a;
}

.text-primary-lter {
    color: #4146ac;
}

.text-primary-dk {
    color: #2c2f74;
}

.text-primary-dker {
    color: #252862;
}

a.bg-info:hover {
    background-color: #19a9d5;
}

a.text-info:hover {
    color: #19a9d5;
}

.text-info {
    color: #23b7e5;
}

.text-info-lt {
    color: #3abee8;
}

.text-info-lter {
    color: #51c6ea;
}

.text-info-dk {
    color: #19a9d5;
}

.text-info-dker {
    color: #1797be;
}

a.bg-success:hover {
    background-color: #3dbdc9;
}

a.text-success:hover {
    color: #3dbdc9;
}

.text-success {
    color: #51c4cf;
}

.text-success-lt {
    color: #65cbd5;
}

.text-success-lter {
    color: #79d2da;
}

.text-success-dk {
    color: #3dbdc9;
}

.text-success-dker {
    color: #33aeba;
}

a.bg-warning:hover {
    background-color: #79797c;
}

a.text-warning:hover {
    color: #79797c;
}

.text-warning {
    color: #868689;
}

.text-warning-lt {
    color: #939396;
}

.text-warning-lter {
    color: #a0a0a2;
}

.text-warning-dk {
    color: #79797c;
}

.text-warning-dker {
    color: #6d6d6f;
}

a.bg-danger:hover {
    background-color: #ff4d55;
}

a.text-danger:hover {
    color: #ff4d55;
}

.text-danger {
    color: #ff676d;
}

.text-danger-lt {
    color: #ff8185;
}

.text-danger-lter {
    color: #ff9a9e;
}

.text-danger-dk {
    color: #ff4d55;
}

.text-danger-dker {
    color: #ff343c;
}

a.bg-dark:hover {
    background-color: #3f4397;
}

a.text-dark:hover {
    color: #3f4397;
}

.text-dark {
    color: #474ba9;
}

.text-dark-lt {
    color: #5357b7;
}

.text-dark-lter {
    color: #6568be;
}

.text-dark-dk {
    color: #3f4397;
}

.text-dark-dker {
    color: #383b85;
}

a.bg-black:hover {
    background-color: #3dbdc9;
}

a.text-black:hover {
    color: #3dbdc9;
}

.text-black {
    color: #51c4cf;
}

.text-black-lt {
    color: #65cbd5;
}

.text-black-lter {
    color: #79d2da;
}

.text-black-dk {
    color: #3dbdc9;
}

.text-black-dker {
    color: #33aeba;
}

.text-white {
    color: #fff;
}

.text-black {
    color: #000;
}

.text-muted {
    color: #c7c7c8;
}

.bg {
    background-color: #f0f3f4;
}

.pos-rlt {
    position: relative;
}

.pos-stc {
    position: static !important;
}

.pos-abt {
    position: absolute;
}

.pos-fix {
    position: fixed;
}

.show {
    visibility: visible;
}

.line {
    width: 100%;
    height: 2px;
    margin: 10px 0;
    font-size: 0;
    overflow: hidden;
}

.line-xs {
    margin: 0;
}

.line-lg {
    margin-top: 15px;
    margin-bottom: 15px;
}

.line-dashed {
    border-style: dashed !important;
    background-color: transparent;
    border-width: 0;
}

.no-line {
    border-width: 0;
}

.no-border,
.no-borders {
    border-color: transparent;
    border-width: 0;
}

.no-radius {
    border-radius: 0;
}

.block {
    display: block;
}

.block.hide {
    display: none;
}

.inline {
    display: inline-block !important;
}

.none {
    display: none;
}

.pull-none {
    float: none;
}

.rounded {
    border-radius: 500px;
}

.clear {
    display: block;
    overflow: hidden;
}

.no-bg {
    background-color: transparent;
    color: inherit;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.l-h {
    line-height: 1.42857143;
}

.l-h-0x {
    line-height: 0;
}

.l-h-1x {
    line-height: 1.2;
}

.l-h-2x {
    line-height: 2em;
}

.l-s-1x {
    letter-spacing: 1;
}

.l-s-2x {
    letter-spacing: 2;
}

.l-s-3x {
    letter-spacing: 3;
}

.font-normal {
    font-weight: normal;
}

.font-thin {
    font-weight: 300;
}

.font-bold {
    font-weight: 700;
}

.text-3x {
    font-size: 3em;
}

.text-2x {
    font-size: 2em;
}

.text-lg {
    font-size: 18px;
}

.text-md {
    font-size: 16px;
}

.text-base {
    font-size: 14px;
}

.text-sm {
    font-size: 13px;
}

.text-xs {
    font-size: 12px;
}

.text-xxs {
    text-indent: -9999px;
}

.text-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-u-c {
    text-transform: uppercase;
}

.text-l-t {
    text-decoration: line-through;
}

.text-u-l {
    text-decoration: underline;
}

.text-active,
.active > .text,
.active > .auto .text {
    display: none !important;
}

.active > .text-active,
.active > .auto .text-active {
    display: inline-block !important;
}

.box-shadow {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}

.box-shadow-lg {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}

.text-shadow {
    font-size: 170px;
    text-shadow: 0 1px 0 #dee5e7, 0 2px 0 #fcfdfd, 0 5px 10px rgba(0, 0, 0, 0.125), 0 10px 20px rgba(0, 0, 0, 0.2);
}

.no-shadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.wrapper-xs {
    padding: 5px;
}

.wrapper-sm {
    padding: 10px;
}

.wrapper {
    padding: 15px;
}

.wrapper-md {
    padding: 20px;
}

.wrapper-lg {
    padding: 30px;
}

.wrapper-xl {
    padding: 50px;
}

.padder-lg {
    padding-left: 30px;
    padding-right: 30px;
}

.padder-md {
    padding-left: 20px;
    padding-right: 20px;
}

.padder {
    padding-left: 15px;
    padding-right: 15px;
}

.padder-v {
    padding-top: 15px;
    padding-bottom: 15px;
}

.no-padder {
    padding: 0 !important;
}

.pull-in {
    margin-left: -15px;
    margin-right: -15px;
}

.pull-out {
    margin: -10px -15px;
}

.b {
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.b-a {
    border: 1px solid #dee5e7;
}

.b-t {
    border-top: 1px solid #dee5e7;
}

.b-r {
    border-right: 1px solid #dee5e7;
}

.b-b {
    border-bottom: 1px solid #dee5e7;
}

.b-l {
    border-left: 1px solid #dee5e7;
}

.b-light {
    border-color: #edf1f2;
}

.b-dark {
    border-color: #474ba9;
}

.b-black {
    border-color: #474ba9;
}

.b-primary {
    border-color: #333787;
}

.b-success {
    border-color: #51c4cf;
}

.b-info {
    border-color: #23b7e5;
}

.b-warning {
    border-color: #868689;
}

.b-danger {
    border-color: #ff676d;
}

.b-white {
    border-color: #fff;
}

.b-dashed {
    border-style: dashed !important;
}

.b-l-light {
    border-left-color: #edf1f2;
}

.b-l-dark {
    border-left-color: #474ba9;
}

.b-l-black {
    border-left-color: #474ba9;
}

.b-l-primary {
    border-left-color: #333787;
}

.b-l-success {
    border-left-color: #51c4cf;
}

.b-l-info {
    border-left-color: #23b7e5;
}

.b-l-warning {
    border-left-color: #868689;
}

.b-l-danger {
    border-left-color: #ff676d;
}

.b-l-white {
    border-left-color: #fff;
}

.b-l-2x {
    border-left-width: 2px;
}

.b-l-3x {
    border-left-width: 3px;
}

.b-l-4x {
    border-left-width: 4px;
}

.b-l-5x {
    border-left-width: 5px;
}

.b-2x {
    border-width: 2px;
}

.b-3x {
    border-width: 3px;
}

.b-4x {
    border-width: 4px;
}

.b-5x {
    border-width: 5px;
}

.r {
    border-radius: 5px 5px 5px 5px;
}

.r-2x {
    border-radius: 10px;
}

.r-3x {
    border-radius: 15px;
}

.r-l {
    border-radius: 5px 0 0 5px;
}

.r-r {
    border-radius: 0 5px 5px 0;
}

.r-t {
    border-radius: 5px 5px 0 0;
}

.r-b {
    border-radius: 0 0 5px 5px;
}

.m-xxs {
    margin: 2px 4px;
}

.m-xs {
    margin: 5px;
}

.m-sm {
    margin: 10px;
}

.m {
    margin: 15px;
}

.m-md {
    margin: 20px;
}

.m-lg {
    margin: 30px;
}

.m-xl {
    margin: 50px;
}

.m-n {
    margin: 0 !important;
}

.m-l-none {
    margin-left: 0 !important;
}

.m-l-xs {
    margin-left: 5px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l {
    margin-left: 15px;
}

.m-l-md {
    margin-left: 20px;
}

.m-l-lg {
    margin-left: 30px;
}

.m-l-xl {
    margin-left: 40px;
}

.m-l-xxl {
    margin-left: 50px;
}

.m-l-n-xxs {
    margin-left: -1px;
}

.m-l-n-xs {
    margin-left: -5px;
}

.m-l-n-sm {
    margin-left: -10px;
}

.m-l-n {
    margin-left: -15px;
}

.m-l-n-md {
    margin-left: -20px;
}

.m-l-n-lg {
    margin-left: -30px;
}

.m-l-n-xl {
    margin-left: -40px;
}

.m-l-n-xxl {
    margin-left: -50px;
}

.m-t-none {
    margin-top: 0 !important;
}

.m-t-xxs {
    margin-top: 1px;
}

.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t {
    margin-top: 15px;
}

.m-t-md {
    margin-top: 20px;
}

.m-t-lg {
    margin-top: 30px;
}

.m-t-xl {
    margin-top: 40px;
}

.m-t-xxl {
    margin-top: 50px;
}

.m-t-n-xxs {
    margin-top: -1px;
}

.m-t-n-xs {
    margin-top: -5px;
}

.m-t-n-sm {
    margin-top: -10px;
}

.m-t-n {
    margin-top: -15px;
}

.m-t-n-md {
    margin-top: -20px;
}

.m-t-n-lg {
    margin-top: -30px;
}

.m-t-n-xl {
    margin-top: -40px;
}

.m-t-n-xxl {
    margin-top: -50px;
}

.m-r-none {
    margin-right: 0 !important;
}

.m-r-xxs {
    margin-right: 1px;
}

.m-r-xs {
    margin-right: 5px;
}

.m-r-sm {
    margin-right: 10px;
}

.m-r {
    margin-right: 15px;
}

.m-r-md {
    margin-right: 20px;
}

.m-r-lg {
    margin-right: 30px;
}

.m-r-xl {
    margin-right: 40px;
}

.m-r-xxl {
    margin-right: 50px;
}

.m-r-n-xxs {
    margin-right: -1px;
}

.m-r-n-xs {
    margin-right: -5px;
}

.m-r-n-sm {
    margin-right: -10px;
}

.m-r-n {
    margin-right: -15px;
}

.m-r-n-md {
    margin-right: -20px;
}

.m-r-n-lg {
    margin-right: -30px;
}

.m-r-n-xl {
    margin-right: -40px;
}

.m-r-n-xxl {
    margin-right: -50px;
}

.m-b-none {
    margin-bottom: 0 !important;
}

.m-b-xxs {
    margin-bottom: 1px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-lg {
    margin-bottom: 30px;
}

.m-b-xl {
    margin-bottom: 40px;
}

.m-b-xxl {
    margin-bottom: 50px;
}

.m-b-n-xxs {
    margin-bottom: -1px;
}

.m-b-n-xs {
    margin-bottom: -5px;
}

.m-b-n-sm {
    margin-bottom: -10px;
}

.m-b-n {
    margin-bottom: -15px;
}

.m-b-n-md {
    margin-bottom: -20px;
}

.m-b-n-lg {
    margin-bottom: -30px;
}

.m-b-n-xl {
    margin-bottom: -40px;
}

.m-b-n-xxl {
    margin-bottom: -50px;
}

.avatar {
    position: relative;
    display: block;
    border-radius: 500px;
    white-space: nowrap;
}

.avatar img {
    border-radius: 500px;
    width: 100%;
}

.avatar i {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    margin: 2px;
    border-width: 2px;
    border-style: solid;
    border-radius: 100%;
}

.avatar i.right {
    left: auto;
    right: 0;
}

.avatar i.bottom {
    left: auto;
    top: auto;
    bottom: 0;
    right: 0;
}

.avatar i.left {
    top: auto;
    bottom: 0;
}

.avatar i.on {
    background-color: #51c4cf;
}

.avatar i.off {
    background-color: #c7c7c8;
}

.avatar i.busy {
    background-color: #ff676d;
}

.avatar i.away {
    background-color: #868689;
}

.avatar.thumb-md i {
    width: 12px;
    height: 12px;
    margin: 3px;
}

.avatar.thumb-sm i {
    margin: 1px;
}

.avatar.thumb-xs i {
    margin: 0;
}

.w-1x {
    width: 1em;
}

.w-2x {
    width: 2em;
}

.w-3x {
    width: 3em;
}

.w-xxs {
    width: 60px;
}

.w-xs {
    width: 90px;
}

.w-sm {
    width: 150px;
}

.w {
    width: 200px;
}

.w-md {
    width: 240px;
}

.w-lg {
    width: 280px;
}

.w-xl {
    width: 320px;
}

.w-xxl {
    width: 360px;
}

.w-full {
    width: 100%;
}

.w-auto {
    width: auto;
}

.h-auto {
    height: auto;
}

.h-full {
    height: 100%;
}

.thumb-xl {
    width: 128px;
    display: inline-block;
}

.thumb-lg {
    width: 96px;
    display: inline-block;
}

.thumb-md {
    width: 64px;
    display: inline-block;
}

.thumb {
    width: 50px;
    display: inline-block;
}

.thumb-sm {
    width: 40px;
    display: inline-block;
}

.thumb-xs {
    width: 34px;
    display: inline-block;
}

.thumb-xxs {
    width: 30px;
    display: inline-block;
}

.thumb-wrapper {
    padding: 2px;
    border: 1px solid #dee5e7;
}

.thumb img,
.thumb-xs img,
.thumb-sm img,
.thumb-md img,
.thumb-lg img,
.thumb-btn img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

.img-full {
    width: 100%;
}

.img-full img {
    width: 100%;
}

.scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.scrollable.hover,
.scrollable.hover > .cell-inner {
    overflow-y: hidden !important;
}

.scrollable.hover:hover,
.scrollable.hover:focus,
.scrollable.hover:active {
    overflow: visible;
    overflow-y: auto;
}

.scrollable.hover:hover > .cell-inner,
.scrollable.hover:focus > .cell-inner,
.scrollable.hover:active > .cell-inner {
    overflow-y: auto !important;
}

.smart .scrollable,
.smart .scrollable > .cell-inner {
    overflow-y: auto !important;
}

.scroll-x,
.scroll-y {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

.scroll-y {
    overflow-y: auto;
}

.scroll-x {
    overflow-x: auto;
}

.hover-action {
    display: none;
}

.hover-rotate {
    -webkit-transition: all 0.2s ease-in-out 0.1s;
    transition: all 0.2s ease-in-out 0.1s;
}

.hover-anchor:hover > .hover-action,
.hover-anchor:focus > .hover-action,
.hover-anchor:active > .hover-action {
    display: inherit;
}

.hover-anchor:hover > .hover-rotate,
.hover-anchor:focus > .hover-rotate,
.hover-anchor:active > .hover-rotate {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
}

.backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0);
}

.backdrop.in {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

/* Toast position */

.toast-top-right {
    position: fixed;
    top: 62px;
    right: 20px;
    z-index: 999999;
}

/*desktop*/
@media screen and (min-width: 992px) {
    .col-lg-2-4 {
        width: 20.000%;
        float: left;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm.show {
        display: inherit !important;
    }

    .no-m-sm {
        margin: 0 !important;
    }
}

/*phone*/
@media (max-width: 767px) {
    .w-auto-xs {
        width: auto;
    }

    .shift {
        display: none !important;
    }

    .shift.in {
        display: block !important;
    }

    .row-2 [class*="col"] {
        width: 50%;
        float: left;
    }

    .row-2 .col-0 {
        clear: none;
    }

    .row-2 li:nth-child(odd) {
        clear: left;
        margin-left: 0;
    }

    .text-center-xs {
        text-align: center;
    }

    .text-left-xs {
        text-align: left;
    }

    .text-right-xs {
        text-align: right;
    }

    .no-border-xs {
        border-width: 0;
    }

    .pull-none-xs {
        float: none !important;
    }

    .pull-right-xs {
        float: right !important;
    }

    .pull-left-xs {
        float: left !important;
    }

    .dropdown-menu.pull-none-xs {
        left: 0;
    }

    .hidden-xs.show {
        display: inherit !important;
    }

    .wrapper-lg,
    .wrapper-md {
        padding: 15px;
    }

    .padder-lg,
    .padder-md {
        padding-left: 15px;
        padding-right: 15px;
    }

    .no-m-xs {
        margin: 0 !important;
    }

    .studio-profile-background {
        display: none;
    }

    .studio-profile-wrapper {
        margin-top: unset !important;
    }
}

.butterbar {
    position: relative;
    margin-bottom: -3px;
    height: 3px;
}

.butterbar .bar {
    position: absolute;
    height: 0;
    width: 100%;
    text-indent: -9999px;
    background-color: #23b7e5;
}

.butterbar .bar:before {
    content: "";
    height: 3px;
    position: absolute;
    left: 50%;
    right: 50%;
    background-color: inherit;
}

.butterbar.active {
    -webkit-animation: changebar 2.25s infinite 0.75s;
    -moz-animation: changebar 2.25s infinite 0.75s;
    animation: changebar 2.25s infinite 0.75s;
}

.butterbar.active .bar {
    -webkit-animation: changebar 2.25s infinite;
    -moz-animation: changebar 2.25s infinite;
    animation: changebar 2.25s infinite;
}

.butterbar.active .bar:before {
    -webkit-animation: movingbar 0.75s infinite;
    -moz-animation: movingbar 0.75s infinite;
    animation: movingbar 0.75s infinite;
}

/* Moving bar */
@-webkit-keyframes movingbar {
    0% {
        left: 50%;
        right: 50%;
    }
    99.9% {
        left: 0%;
        right: 0%;
    }
    100% {
        left: 50%;
        right: 50%;
    }
}

@-moz-keyframes movingbar {
    0% {
        left: 50%;
        right: 50%;
    }
    99.9% {
        left: 0%;
        right: 0%;
    }
    100% {
        left: 50%;
        right: 50%;
    }
}

@keyframes movingbar {
    0% {
        left: 50%;
        right: 50%;
    }
    99.9% {
        left: 0%;
        right: 0%;
    }
    100% {
        left: 50%;
        right: 50%;
    }
}

/* change bar */
@-webkit-keyframes changebar {
    0% {
        background-color: #23b7e5;
    }
    33.3% {
        background-color: #23b7e5;
    }
    33.33% {
        background-color: #868689;
    }
    66.6% {
        background-color: #868689;
    }
    66.66% {
        background-color: #333787;
    }
    99.9% {
        background-color: #333787;
    }
}

@-moz-keyframes changebar {
    0% {
        background-color: #23b7e5;
    }
    33.3% {
        background-color: #23b7e5;
    }
    33.33% {
        background-color: #868689;
    }
    66.6% {
        background-color: #868689;
    }
    66.66% {
        background-color: #333787;
    }
    99.9% {
        background-color: #333787;
    }
}

@keyframes changebar {
    0% {
        background-color: #23b7e5;
    }
    33.3% {
        background-color: #23b7e5;
    }
    33.33% {
        background-color: #868689;
    }
    66.6% {
        background-color: #868689;
    }
    66.66% {
        background-color: #333787;
    }
    99.9% {
        background-color: #333787;
    }
}

#conference-container iframe {
    height: calc(100vh - 50px) !important;
    position: static;
    z-index: 99999;
    top: 50px;
    bottom: 0;
}

@media screen and (min-width: 768px) {
    #conference-container iframe {
        width: calc(100% - 199px) !important;
    }

    .app.app-aside-folded #conference-container iframe {
        width: calc(100% - 59px) !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    #conference-container iframe {
        height: 500px !important;
        width: 100% !important;
    }

    .app.app-aside-folded #conference-container iframe {
        height: 500px !important;
        width: 100% !important;
    }
}

@media screen and (max-width: 768px) and (max-width: 1024px) {
    #conference-container iframe .atlaskit-portal > div > div div {
        left: -20px !important;
        transform: scale(0.8) !important;
    }

    .app.app-aside-folded #conference-container iframe .atlaskit-portal > div > div div {
        left: -20px !important;
        transform: scale(0.8) !important;
    }
}

.jitsi_force_fixed > iframe {
    position: fixed !important;
}

.jitsi_mobile_app_info {
    z-index: 999999;
    width: 100%;
    background-color: rgba(58, 192, 205, 1);
    color: white;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.jitsi_mobile_app_info > p {
    margin: 0 !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    position: relative !important;
}

.jitsi_mobile_app_info > p > span {
    position: absolute !important;
    right: 10px !important;
}


.jitsi_mobile_app_info > div > ol {
    margin: 0 !important;
    padding-left: 20px;
}

.jitsi_mobile_app_info > div {
    max-height: 0 !important;
    transition-duration: 250ms;
    overflow: auto;
}

.jitsi_mobile_app_info > div.show {
    max-height: 650px !important;
    transition-duration: 250ms;
}
