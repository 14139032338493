/************************************ LANDING PAGE BACKGROUND GRADIENT *****************************************/

.bg-gradient {
    background-image: linear-gradient(232deg, #51c4cf, #53bbcc 18%, #57a1c3 47%, #5e78b4 84%, #6164ad);
}

/****************************************** LANDING PAGE CAROUSEL **********************************************/

.carousel-text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.carousel-text {
    z-index: 10;
    letter-spacing: 1.3px;
    color: #fff;
    text-align: center;
    text-shadow: 0 2px 4px rgba(39, 60, 78, 0.3);
}

.carousel-text > span {
    font-size: 39px;
}

@media(max-width: 768px){
    .carousel-text > span {
        font-size: 25px;
    }
}

.carousel-btn {
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 50px auto;
    width: fit-content;
    border-width: 0;
    padding: 3px;
    border-radius: 50px;
    background-image: linear-gradient(255deg, rgba(81, 196, 207, 0.39), rgba(83, 187, 204, 0.39) 18%,
    rgba(87, 161, 195, 0.39) 47%, rgba(94, 120, 180, 0.39) 84%, rgba(97, 100, 173, 0.39));
}

.carousel-btn > a {
    background-color: rgba(255, 255, 255, 0.39);
    width: 100%;
    border: none;
}

.carousel-btn > a > p {
    padding: 0;
    margin: 7px 10px;
}

.carousel-content {
    /* Firefox */
    height: -moz-calc(100vh - 80px);
    /* WebKit */
    height: -webkit-calc(100vh - 80px);
    /* Opera */
    height: -o-calc(100vh - 80px);
    /* Standard */
    height: calc(100vh - 80px);

    object-fit: cover;
    margin:auto;
    width:100%;
}

.text-bold {
    font-weight: bold;
}

.how-text {
    color: #6164ad;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.3px;
}

.bg-partner {
    background-color: #51c4cf;
    background-image: url("../../../c/img/landing/pattern.svg");
    background-repeat: repeat;
}

.partner-text {
    font-weight: 300;
    line-height: 1.36;
    letter-spacing: 0.8px;
}

@media (max-width: 993px) {
    .bg-partner .h2{
        font-size: 18px;
    }
}

.bg-about {
    display: flex;
    background-color: #c7d0e0;
    color: #273c4e;
    height: 500px;
}

.img-outside-right img{
    width: 100%;
    height: 100%;
}

.about-image{
    width: 100%;
}

@media (min-width: 993px) {
    html,body,.fluid-container {
        height: 100%;
    }

    .flexbox-container {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        height: 100%;
    }
}

@media (max-width: 993px){
    .bg-about {
        display: inline;
    }
}

/************************************** ACCESS LOGO ************************************************************/

.access-header-wrapper {
    display: flex;
    justify-content: center;
}

.access-header-logo {
    min-width: 129px;
    min-height: 35px;
    display: inline-block;

    background-image: url("../../../c/img/logo/logo-with-name.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    text-align: center;
}

/*************************************** AGREE LINK ************************************************************/

.agree-link {
    color: #337ab7;
}

/********************************* HIDE PLAY BUTTON ON VIDEO ***************************************************/