/*************************************************** CUSTOM HELPERS CLASS *********************************************/

/*---------- SPACING ----------*/


/* Padding Zero */

.p-0 {
    padding: 0!important;
}

/* Padding Top */

.p-t-10 {
    padding-top: 10px!important;
}

.p-t-15 {
    padding-top: 15px!important;
}

.p-t-20 {
    padding-top: 20px!important;
}

.p-t-30 {
    padding-top: 30px!important;
}

.p-t-40 {
    padding-top: 40px!important;
}

.p-t-50 {
    padding-top: 50px!important;
}

/* Padding Bottom */

.p-b-0 {
    padding-bottom: 0!important;
}

.p-b-10 {
    padding-bottom: 10px!important;
}

.p-b-15 {
    padding-bottom: 15px!important;
}

.p-b-20 {
    padding-bottom: 20px!important;
}

.p-b-30 {
    padding-bottom: 30px!important;
}

.p-b-40 {
    padding-bottom: 40px!important;
}

.p-b-50 {
    padding-bottom: 50px!important;
}

/* Padding Left */

.p-l-0 {
    padding-left: 0!important;
}

.p-l-3 {
    padding-left: 3px!important;
}

.p-l-5 {
    padding-left: 5px!important;
}

.p-l-10 {
    padding-left: 10px!important;
}

.p-l-20 {
    padding-left: 20px!important;
}

.p-l-30 {
    padding-left: 30px;
}

.p-l-40 {
    padding-left: 40px;
}

.p-l-50 {
    padding-left: 50px;
}

/* Padding Right */

.p-r-0 {
    padding-right: 0!important;
}

.p-r-5 {
    padding-right: 5px!important;
}

.p-r-10 {
    padding-right: 10px!important;
}

.p-r-20 {
    padding-right: 20px!important;
}

.p-r-30 {
    padding-right: 30px!important;
}

.p-r-40 {
    padding-right: 40px;
}

.p-r-50 {
    padding-right: 50px;
}

/* Padding Left-Right */

.p-l-r-0 {
    padding-left: 0!important;
    padding-right: 0!important;
}

.p-l-r-5 {
    padding-left: 5px!important;
    padding-right: 5px!important;
}

.p-l-r-10 {
    padding-left: 10px!important;
    padding-right: 10px!important;
}

.p-l-r-20 {
    padding-left: 20px!important;
    padding-right: 20px!important;
}

.p-l-r-30 {
    padding-left: 30px!important;
    padding-right: 30px!important;
}

.p-l-r-40 {
    padding-left: 40px;
    padding-right: 40px;
}

.p-l-r-50 {
    padding-left: 50px;
    padding-right: 50px;
}

@media (max-width: 992px) {
    .p-t-tablet-5{
        padding-top: 5px !important;
    }
}

@media (min-width: 992px) {
    .p-l-desktop-5{
        padding-left: 5px !important;
    }
}

/* Remove padding from extra small devices like mobile phones in landscape mode */
@media (max-width: 768px) {
    .p-l-r-mobile-0{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .p-mobile-0{
        padding: 0 !important;
    }

    .p-l-mobile-0{
        padding-left: 0 !important;
    }

    .p-l-mobile-5{
        padding-left: 5px !important;
    }

    .p-r-mobile-5{
        padding-right: 5px !important;
    }

    .p-l-mobile-10{
        padding-left: 10px !important;
    }

    .p-r-mobile-10{
        padding-right: 10px !important;
    }

    .p-r-mobile-0{
        padding-right: 0 !important;
    }

    .p-t-mobile-0{
        padding-top: 0 !important;
    }

    .p-t-mobile-5{
        padding-top: 5px !important;
    }

    .p-t-mobile-15{
        padding-top: 15px !important;
    }

    .p-t-mobile-25{
        padding-top: 25px !important;
    }

    .p-b-mobile-0{
        padding-top: 0 !important;
    }

    .p-b-mobile-5{
        padding-top: 5px !important;
    }
}

/*Floating only for mobile, so I can reallocate the elements for mobile version*/

@media (max-width: 768px) {
    .float-left-mobile{
        float: left;
    }

    .float-right-mobile{
        float: right;
    }
}

/* Add full width or height to element only for mobile devices in landscape mode */
@media (max-width: 768px) {
    .w-full-mobile{
        width: 100%;
    }

    .w-full-max-60-mobile{
        width: 100%;
        max-width: 60px;
    }

    .h-full-mobile{
        height: 100%;
     }

    .h-half-mobile{
        height: 50%;
    }

    .w-half-mobile{
        width: 50%;
    }

    .height-80-mobile{
        height: 80px;
    }

    .default-display-mobile{
        display: block;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .h-full-tablet{
        height: 100%;
    }

    .w-full-tablet{
        width: 100%;
    }

    .h-half-tablet{
        height: 50%;
    }

    .w-half-tablet{
        width: 50%;
    }
}

@media (min-width: 992px) {
    .h-full-desktop{
        height: 100%;
    }

    .w-full-desktop{
        width: 100%;
    }

    .h-half-desktop{
        height: 50%;
    }

    .w-half-desktop{
        width: 50%;
    }
}

.row-flex {
    display: flex;
    align-items: center;
}


/* TEXT AREA UTIL */

.textarea-resize-height{
    resize: vertical;
}

.textarea-resize-width{
    resize: horizontal;
}

.textarea-resize-none{
    resize: none;
}